import React, { useContext, useEffect, useState } from 'react';

import Phone from '../content/imgs/icons/phone-b.png';
import Email from '../content/imgs/icons/email-b.png';
import { useNavigate } from 'react-router-dom';
import Loading from './components/loading';
import { ClientsContext } from '../contexts/Context';

export default function Procedimentos() {
    const navigate = useNavigate();
    const [procList, setProcList] = useState<any>(null);
    const [selectedProc, setSelectedProc] = useState<number>();
    const context = useContext(ClientsContext);

    useEffect(() => {
        if (context?.settings)
            setProcList(context?.settings?.procedimentos)
    }, [context?.settings]);

    function Procedimento({ ...props }) {
        return (
            <div onClick={() => { selectedProc === props.id ? setSelectedProc(-1) : setSelectedProc(props.id) }} className={`w-full h-[3rem] transition-all duration-500 ease-in-out bg-[var(--primary)] rounded-[20px] ${props.id === selectedProc && ' scale-[1.1] rounded-t-[20px] rounded-b-[0] '}`}>
                <div className='w-full h-[3rem] px-4 rounded-[20px] cursor-pointer bg-[var(--primary)] flex justify-between items-center text-white text-[1rem] quatro:text-[1.2rem] uppercase font-mybold font-bold '>
                    <p>{props.id}. &ensp;{props.title}</p>
                    <p>{selectedProc === props.id ? '▲' : '▼'}</p>
                </div>
                {selectedProc === props.id &&
                    <ol className='bg-[var(--primary)] shadow-lg p-4 rounded-b-[20px] text-[.8rem] list-disc px-8 flex flex-col gap-4 text-white border-b-4 border-black'>
                        {
                            props.text.map((step: string) => {
                                return (
                                    <li key={step}>{step}</li>
                                )
                            })
                        }
                    </ol>
                }
            </div>
        )
    }

    if (!procList) {
        return (
            <Loading/>
        );
    }

    return (
        <>
            <button onClick={() => navigate('/home')} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='flex flex-col justify-center w-full gap-6 py-20 cinco:py-8'>
                <h1 className='text-[1.2rem] font-mybold text-[var(--primary)] text-center mb-8'>PROCEDIMENTOS</h1>
                <div className='flex flex-col items-center w-full gap-4'>
                    {
                        procList && Object.keys(procList).map((title: string, index: number) => {
                            return (
                                <Procedimento
                                    key={index}
                                    id={index + 1}
                                    title={title}
                                    text={procList[title]}
                                />
                            )
                        })
                    }
                </div>
                <div className='flex justify-center'>
                    <button onClick={() => window.open(process.env.REACT_APP_FORMACOES_LINK, '_blank')} className='w-1/2 text-[1rem] bg-[var(--orange)] border-[1px] hover:border-[1px] hover:border-black rounded-[10px] py-2 text-white'>FORMAÇÕES</button>
                </div>
                <div className='flex items-center justify-center w-full gap-4 mt-4'>
                    <a href="tel:+351935625800"><img src={Phone} alt="phone" className='w-[4rem] h-[4rem] cursor-pointer' /></a>
                    <a href="mailto:info@airpark.pt"><img src={Email} alt="email" className='w-[4rem] h-[4rem] cursor-pointer' /></a>
                </div>
            </div>
        </>
    );
}

