import React from 'react';

interface DialogProps {
    message: string,
    opened: boolean,
    onDialog: any,
    onClose: any,
}

export default function Dialog({ message, opened, onDialog, onClose }: DialogProps) {
    const handleClose = () => {
        onDialog(false);
        onClose();
    };
    return (
        <div className='relative w-full z-[999]'>
            {opened && <div className='fixed inset-0 bg-black opacity-30'></div>}
            <dialog open={opened} id='prompt' className='fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] w-[18rem] quatro:w-[22rem] cinco:w-[25rem] cinco:mx-0 h-[15rem] rounded-[18px] bg-[var(--primary)]'>
                <div className='absolute flex flex-col justify-center items-center top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] gap-10'>
                    <h3 className='text-white uppercase font-bold text-balance text-center text-[.9rem]'>{message}</h3>
                    <div className='flex items-center justify-center w-full gap-4'>
                        <button className="w-[8rem] h-[2rem] bg-[var(--yellow)] rounded-full text-black" onClick={handleClose}>OK</button>
                    </div>
                </div>
            </dialog>
        </div>
    );
}