import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteField, doc, updateDoc } from 'firebase/firestore';
import {  db } from '../../firebase';
import Dialog from '../components/alertDialog';
import Bin from '../../content/imgs/icons/bin.png';
// import Premios from '../../content/imgs/menu/premios.png';
import Loading from '../components/loading';
import { ClientsContext } from '../../contexts/Context';

export default function Procedimentos() {

    const navigate = useNavigate();
    const context = useContext(ClientsContext);

    const [inputs, setInputs] = useState<any>(null);

    const [procedimento, setProcedimento] = useState('');
    const [newProcedimento, setNewProcedimento] = useState('');
    const [procedimentos, setProcedimentos] = useState<any>(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        if (context?.settings)
            setProcedimentos(context?.settings?.procedimentos)
    }, [context?.settings]);

    useEffect(() => {
        if (procedimentos && procedimento !== '') {
            setInputs(procedimentos[procedimento]);
        }
    }, [procedimento, procedimentos]);

    async function remove() {
        if (!window.confirm(`Tem a certeza que quer apagar o procedimento '${procedimento}'?`)) {
            return;
        }
        const newData = Object.fromEntries(
            Object.entries(procedimentos).filter(([key, _]) => key !== procedimento)
        );
        try {
            const updatePayload = { [procedimento]: deleteField() };
            await updateDoc(doc(db, 'settings', 'procedimentos'), updatePayload)
                .then(() => {
                    openDialog(`Procedimento '${procedimento}' removido.`, () => null);
                    setProcedimento('');
                    setProcedimentos(newData);
                })
        } catch (error) {
            console.error("Error updating document: ", error);
            openDialog(`Erro ao remover '${procedimento}'.`, () => {});
        }
    }

    async function edit() {
        if (procedimento === '') {
            openDialog('Campos inválidos.', () => null)
            return;
        }
        try {
            await updateDoc(doc(db, 'settings', 'procedimentos'), procedimentos)
                .then(() => {
                    openDialog(`Procedimento '${procedimento}' atualizado.`, () => null);
                })
        } catch (error) {
            console.error("Error updating document: ", error);
            openDialog(`Erro ao atualizar '${procedimento}'.`, () => {});
        }
    }

    async function add() {
        if (newProcedimento === '') {
            openDialog('Nome inválido.', () => null)
            return;
        }
        const newData = { ...procedimentos };
        newData[newProcedimento] = [];
        try {
            await updateDoc(doc(db, 'settings', 'procedimentos'), newData)
                .then(() => {
                    openDialog(`Procedimento '${newProcedimento}' adicionado.`, () => null);
                    setProcedimentos(newData);
                    setNewProcedimento('');
                    setProcedimento('');
                })
        } catch (error) {
            console.error("Error updating document: ", error);
            openDialog(`Erro ao adicionar '${newProcedimento}'.`, () => {});
        }
    }

    async function addInput() {
        setInputs((inputs: any) => [...inputs, '']);
    }

    async function removeInput(ele: string, index: number) {
        if (ele !== '' && !window.confirm(`Tem a certeza que quer apagar este procedimento?`)) {
            return;
        }
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);
        const updatedProcedimentos = { ...procedimentos };
        updatedProcedimentos[procedimento] = newInputs;
        setProcedimentos(updatedProcedimentos);
    }

    function handleInputChange(e: any, index: number) {
        const newInputs = [...inputs];
        newInputs[index] = e.target.value;
        setInputs(newInputs);
        const updatedProcedimentos = { ...procedimentos };
        updatedProcedimentos[procedimento] = newInputs;
        setProcedimentos(updatedProcedimentos);
    }

    if (!procedimentos) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full pw-[8vw] flex justify-center'>
            <button onClick={() => navigate('/settings')} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='w-full cinco:w-[25rem] flex flex-col items-center gap-10 py-20 cinco:py-8'>
                <h1 className='text-[var(--primary)] font-mybold text-[1.2rem] cinco:text-[1.5rem] uppercase'>PROCEDIMENTOS</h1>
                <div className='cinco:px-[3rem] w-full flex flex-col gap-8'>
                    <select
                        name="procedimento"
                        value={procedimento}
                        className="select"
                        onChange={(e) => setProcedimento(e.target.value)}
                    >
                        <option value="">Selecionar</option>
                        {
                            Object.keys(procedimentos).map((ele, index) => {
                                return (
                                    <option key={index} value={ele}>{ele}</option>
                                )
                            })
                        }
                    </select>
                    {
                        procedimento &&
                        <>
                            <div id='newInputs-container' className='flex flex-col w-full gap-4'>
                                {
                                    (inputs && inputs.length !== 0) ? inputs.map((input: any, index: number) => (
                                        <div key={index} className="relative flex items-center gap-2">
                                            <input className='settings-proc-input input' placeholder='| Texto' value={input} onChange={e => handleInputChange(e, index)} />
                                            <button onClick={() => removeInput(input, index)} className="absolute top-[.35rem] right-[.35rem] bg-[var(--red)] text-white w-8 rounded-full aspect-square flex justify-center items-center">
                                                <img src={Bin} alt="bin" className='w-5' />
                                            </button>
                                        </div>
                                    ))
                                        :
                                        <p className='text-center'>Lista vazia.</p>
                                }
                            </div>
                            <button onClick={addInput} className='bg-[var(--primary)] rounded-full py-1 px-6 text-center cursor-pointer border-[1px] hover:border-[1px] hover:border-black text-white'>+ protocolo</button>
                            <div className='flex w-full gap-4'>
                                <button onClick={edit} className='w-full text-[1rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-black'>SALVAR</button>
                                <button onClick={remove} className='w-full text-[1rem] bg-[var(--red)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-white flex gap-2 items-center justify-center'>
                                    APAGAR
                                    <img src={Bin} alt="icon" className='w-5' />
                                </button>
                            </div>
                        </>
                    }
                    <div className='w-full h-1 bg-[var(--primary)]'></div>
                    <div className='flex flex-col w-full gap-6'>
                        <h2 className='text-[var(--primary)] font-mybold text-[1rem] cinco:text-[1.2rem] uppercase text-center'>Adicionar Procedimento</h2>
                        <div className='flex flex-col w-full gap-2'>
                            <input type="text" value={newProcedimento} onChange={(e) => setNewProcedimento(e.target.value)} className='relative w-full px-2 py-1 border-2 border-black rounded-full' placeholder='| Título' />
                        </div>
                        <button onClick={add} className='w-full text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-white uppercase'>+ Adicionar</button>
                    </div>
                </div>
                <Dialog
                    message={dialogMessage}
                    opened={dialogOpen}
                    onDialog={closeDialog}
                    onClose={dialogOnClose}
                />
            </div>
        </div>
    );
}

