import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import Dialog from '../components/alertDialog';
import { db } from '../../firebase';
import { ClientsContext } from '../../contexts/Context';
import Bin from '../../content/imgs/icons/bin.png';

export default function AddParqueExterno() {

    const navigate = useNavigate();
    const context = useContext(ClientsContext);

    const [city, setCity] = useState('faro');
    const [externalParks, setExternalParks] = useState<any>(null);
    void setCity;
    const [parkName, setParkName] = useState('');

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        if (context?.settings) {
            setExternalParks(context?.settings?.externalParks);
        }
    }, [context?.settings]);

    async function add() {
        if (!parkName) {
            openDialog('Preencha todos os campos.', () => null)
            return;
        }
        if (!window.confirm(`Tem a certeza que quer criar o parque '${parkName}'?`)) {
            return;
        }
        try {
            const brandDocRef = doc(db, 'settings', 'externalParks');
            let parksArray = externalParks || [];
            parksArray.push(parkName);
            await updateDoc(brandDocRef, { all: parksArray });
            openDialog(`Parque '${parkName}' criado com sucesso.`, () => { });
            setExternalParks(parksArray);
            context?.setSettings({ ...context.settings, externalParks: parksArray });
            setParkName('');
        } catch (error) {
            console.error("Error editing park: ", error);
            openDialog(`Erro ao editar o parque '${parkName}'.`, () => { });
        }
    }

    async function remove(ele: string, index: number) {
        if (!window.confirm(`Tem a certeza que quer apagar o tipo '${ele}'?`)) {
            return;
        }
        let newList = externalParks;
        newList.splice(index, 1);
        // Remove from firebase
        try {
            await updateDoc(doc(db, 'settings', 'externalParks'), { all: newList })
                .then(() => {
                    openDialog(`Parque '${ele}' removido.`, () => null);
                    setExternalParks(newList);
                    context?.setSettings({ ...context.settings, externalParks: newList })
                })
        } catch (error) {
            console.error("Error updating document: ", error);
            openDialog(`Erro ao remover ${ele}.`, () => { });
        }
    }

    return (
        <div className='w-full pw-[8vw] flex justify-center'>
            <button onClick={() => navigate(-1)} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='w-full cinco:w-[25rem] flex flex-col items-center gap-10 py-20 cinco:py-8'>
                <div className='cinco:px-[3rem] w-full flex flex-col gap-8'>
                    <div className='flex flex-col w-full gap-6'>
                        <h2 className='text-[var(--primary)] font-mybold text-[1rem] cinco:text-[1.2rem] uppercase text-center'>Adicionar parque externo</h2>
                        <div className='flex flex-col w-full gap-2'>
                            <div className='flex gap-4'>
                                <p><span className='font-semibold'>Cidade:</span> {city}</p>
                            </div>
                            <input type="text" value={parkName} onChange={(e) => setParkName(e.target.value)} className='relative w-full px-2 py-1 border-2 border-black rounded-full' placeholder='Nome do parque' />
                        </div>
                        <button onClick={add} className='w-full text-[1rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-black uppercase'>+ Adicionar</button>
                        <div className='flex flex-col w-full gap-4'>
                            {
                                externalParks && externalParks.map((ele: string, index: number) => {
                                    return (
                                        <div key={index} className='relative flex justify-between w-full p-2 border-2 border-black rounded-full'>
                                            <p>{ele}</p>
                                            <div onClick={() => remove(ele, index)} className='w-6 aspect-square bg-[var(--red)] rounded-full flex justify-center items-center cursor-pointer'><img src={Bin} alt="bin" className='w-4' /></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <Dialog
                    message={dialogMessage}
                    opened={dialogOpen}
                    onDialog={closeDialog}
                    onClose={dialogOnClose}
                />
            </div>
        </div>
    );
}

