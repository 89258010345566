import React, { useContext, useEffect, useState } from 'react';
import Card from '../components/parkCard';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/loading';
import { ClientsContext } from '../../contexts/Context';

export default function Parques() {
    const navigate = useNavigate();
    const context = useContext(ClientsContext);
    const [parkData, setParkData] = useState<any>(null);

    useEffect(() => {
        if (context?.parks && context?.settings?.externalParks)
            setParkData({...context.parks, 'externo': context?.settings?.externalParks});
    }, [context.parks, context?.settings?.externalParks]);

    useEffect(() => {
        if (!context?.clients || context?.clients.length === 0 || !context?.clients.some((client: any) => client?.stats === 'recolhido' || client?.stats === 'em entrega' || client?.stats === 'em movimento')) {
            context?.getClients('stats', ['recolhido', 'em entrega', 'em movimento']);
        }
    }, [context?.clients, context]);

    if (!parkData) {
        return (
            <Loading/>
        )
    }

    return (

        <>
            <button onClick={() => navigate('/home')} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='flex flex-col justify-center w-full gap-6 py-20 cinco:py-8'>
                <h1 className='text-[1.2rem] font-mybold text-[var(--primary)] text-center'>PARQUES</h1>
                <div className='flex flex-col w-full gap-2'>
                    {parkData && parkData && Object.entries(parkData).map(([brand, value]: any) => (
                        <div key={brand} className='flex flex-col w-full gap-4'>
                            {Object.keys(value).map((park: any, index: number) => (
                                <Card
                                    key={index}
                                    park={parkData[brand][park]}
                                    brand={brand}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
