import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ClientsContext } from '../../contexts/Context';
import Card from '../components/clientCard';

export default function Parque() {
    const location = useLocation();
    const { park } = location.state;
    const { name = "" } = useParams();
    const navigate = useNavigate();
    const [cards, setCards] = useState<any>([]);
    const context = useContext(ClientsContext);

    useEffect(() => {
        if (context?.clients && park && name) {
            let cardsObject = context.clients.reduce((acc: any, card: any) => {
                if (card?.stats === 'recolhido' && card?.park && (card?.park).split(':')[1].toLowerCase() === name.toLowerCase() && (card?.park).split(':')[0].toLowerCase() === 'externo') {
                    const key = `${card.row}-${card.spot}`;
                    acc[key] = card;
                }
                return acc;
            }, {});
            setCards(cardsObject);
        }
    }, [context?.clients, park?.name, park, name]);

    if (!context || !park || !cards) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <button onClick={() => navigate(-1)} className='absolute top-8 left-[5vw] quatro:left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='flex flex-col justify-center w-full gap-6 py-20 mil:overflow-x-auto cinco:py-8'>
                <h1 className='text-[1.2rem] font-mybold text-[var(--primary)] text-center mb-8'>PARQUE</h1>
                <p className='text-[1.5rem] font-mybold text-[var(--primary)] text-center'>{park?.name}</p>
                {
                    cards && Object.keys(cards).length > 0 &&
                    <p className='w-full font-bold text-center'>{Object.keys(cards).length} carro{Object.keys(cards).length > 1 && 's'} no parque</p>
                }
                <div className='flex flex-col w-full gap-2'>
                    {
                        Object.keys(cards).length !== 0 ?
                            Object.keys(cards).map((key: any, index: any) => {
                                return (
                                    <Card
                                        key={index}
                                        client={cards[key]}
                                        href={"consulta"}
                                        type={"consulta"}
                                    />
                                );
                            })
                            : <p className='text-center'>Nenhum carro encontrado</p>
                    }
                </div>
            </div>
        </>
    );
}

