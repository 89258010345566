import React, { useContext, useEffect, useRef, useState } from 'react';
import Logo from '../../content/imgs/logos/multicar.png';
import Dialog from '../components/alertDialog';
import { ClientsContext } from '../../contexts/Context';
import { useNavigate } from 'react-router-dom';

export default function CreateUser() {
    const context = useContext(ClientsContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('');
    const [parks, setParks] = useState<any>([]);
    const [parksOptions, setParksOptions] = useState<any>([]);

    const selectRef = useRef<HTMLDivElement>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    useEffect(() => {
        if (context?.settings)
            setParksOptions(context?.settings?.brands);
    }, [context?.settings]);


    useEffect(() => {
        function handleClickOutside(event: any) {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selectRef]);

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    const handleSelectOption = (ele: any) => {
        setParks((prev: any) => {
            if (prev.some((park: string) => park === ele)) {
                return prev.filter((park: any) => park !== ele);
            } else {
                return [...prev, ele]
            }
        });
    };

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    function isValidEmail(email: string): boolean {
        const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailPattern.test(email);
    }

    function isValidPassword(password: string): boolean {
        return password.length >= 6 && /[a-zA-Z]/.test(password) && /\d/.test(password);
    }

    const create = async () => {
        if (email === '' || password === '' || type === '' || name === '') {
            openDialog('Preencha todos os campos.', () => null)
            return;
        }
        if (!isValidEmail(email)) {
            openDialog('Email inválido.', () => null)
            return;
        }
        if (!isValidPassword(password)) {
            openDialog('Password deve conter:\n- 6 caractéres no mínimo\n- Pelo menos um número e uma letra.', () => null)
            return;
        }
        try {
            fetch(`${process.env.REACT_APP_API}/create-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password, type, parks, name })
            })
                .then(() => {
                    openDialog('User criado com sucesso.', () => window.location.href = '/home')
                })
                .catch(error => {
                    openDialog('Erro ao criar utilizador.', () => {});
                    console.error('Erro ao criar user:', error);
                });
        } catch (err) {
            openDialog('Erro ao criar utilizador.', () => {});
            console.error(err);
        }
        // TODO: Enviar email com credenciais
    }

    return (
        <>
            <button onClick={() => navigate(-1)} className='absolute top-8 left-[5vw] quatro:left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <img src={Logo} alt="logo" className='w-[10rem] cinco:w-[14rem] py-10' />
            <h1 className='text-[var(--primary)] font-mybold text-[1.2rem] cinco:text-[1.5rem]'>Criar Utilizador</h1>
            <input type="text" value={name} placeholder='Nome' onChange={(e) => setName(e.target.value)} className="input" />
            <input type="text" value={email} placeholder='Email | Username' onChange={(e) => setEmail(e.target.value)} className="input" />
            <select
                name="type"
                value={type}
                className="select"
                onChange={(e) => setType(e.target.value)}
            >
                <option value="">Tipo</option>
                <option value={"Spotter"} className="text-black bg-white">Spotter</option>
                <option value={"Driver"} className="text-black bg-white">Driver</option>
                <option value={"Admin"} className="text-black bg-white">Admin</option>
            </select>
            {
                type !== 'Admin' && type !== '' &&
                <div
                    className="relative w-full h-10 px-6 py-2 border-2 border-black rounded-full cursor-pointer"
                    onClick={handleToggleDropdown}
                    ref={selectRef}
                >
                    {parks.length} {`selecionado${parks.length === 1 ? '' : 's'}`}
                    {isDropdownOpen && (
                        <div className="absolute w-full left-0 top-10 bg-white border-2 border-black rounded-md z-10 h-[15rem] overflow-y-scroll">
                            {parksOptions && parksOptions.map((ele: any, index: number) => (
                                <div
                                    key={index}
                                    className="flex items-center gap-2 p-2 pl-4 hover:bg-gray-100"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleSelectOption(ele);
                                    }}>
                                    <div className={`w-4 h-4 border-[1px] border-black rounded-[4px] flex justify-center items-center ${parks.some((park: any) => park === ele) ? 'bg-[var(--primary)]' : ''}`}>
                                        <span className={`${parks.some((park: any) => park === ele) ? 'flex' : 'hidden'} text-white text-[.8rem]`}>X</span>
                                    </div>
                                    <p className="ml-2 text-sm capitalize">{ele}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            }
            <input type="password" value={password} placeholder='Password' onChange={(e) => setPassword(e.target.value)} className="input" />
            <button onClick={create} className='text-[1rem] bg-[var(--primary)] hover:border-[1px] hover:border-black rounded-full  py-1 text-white px-10'>+ CRIAR</button>
            <Dialog
                message={dialogMessage}
                opened={dialogOpen}
                onDialog={closeDialog}
                onClose={dialogOnClose}
            />
        </>
    );
}

