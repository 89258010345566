import React, { useContext, useEffect, useState } from 'react';
import DatePainel from '../components/date';
import Card from '../components/clientCard';
import { ClientsContext } from '../../contexts/Context';
import { useNavigate } from 'react-router-dom';
import { formatDate, formatDateSimple, useDate } from '../../contexts/DateContext';
import { exportExcel } from '../components/export';
import DownloadIcon from '../../content/imgs/icons/download.png';

export default function Entregas() {
    const navigate = useNavigate();

    const { date } = useDate();
    const [cards, setCards] = useState([]);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const context = useContext(ClientsContext);
    const [type, setType] = useState('');
    const [selectBrand, setSelectBrand] = useState('all');

    function getMonthAndDay(date: string) {
        return `${date.split('/')[0]}/${date.split('/')[1]}`;
    }

    useEffect(() => {
        if (context?.user) {
            setType(context?.user.type);
        }
    }, [context?.user]);

    useEffect(() => {
        if (context?.clients && context?.clients.length > 0 && context?.clients.some((client:any) => client?.stats !== 'cancelado')) {
            setIsLoading(false);
            let simplifiedDate = formatDateSimple(date);

            let newCards = context.clients.filter((card: any) => {
                return card?.stats === 'entregue';
            });

            // Filter by date when search is empty
            if (search.trim() === '') {
                newCards = newCards.filter((card: any) => {
                    return getMonthAndDay(card?.checkOut.split(', ')[0]) === simplifiedDate;
                });
            }

            newCards = newCards.sort((a: any, b: any) => {
                const timeA = a?.checkIn.split(" ")[1];
                const timeB = b?.checkIn.split(" ")[1];
                return timeA.localeCompare(timeB);
            });

            // Filter by search when search is not empty
            if (search.trim() !== '') {
                newCards = newCards.filter((card: any) => {
                    const searchTerm = search.toLowerCase();
                    return (card?.licensePlate).toString().toLowerCase().includes(searchTerm)
                        || (card?.name).toString().toLowerCase().includes(searchTerm)
                        || (card?.alocation).toString().toLowerCase().includes(searchTerm)
                        || (card?.idClient).toString().toLowerCase().includes(searchTerm);
                });
            }

            // Filter by brand
            if (selectBrand !== 'all') {
                newCards = newCards.filter((card: any) => {
                    return card?.parkBrand.toLowerCase() === selectBrand.toLowerCase();
                });
            }

            // Calc Total
            const totalSum = newCards.reduce((acc: any, card: any) => {
                const value = card?.bookingPrice;
                if (value && !isNaN(parseFloat(value))) return acc + parseFloat(value);
                return acc;
            }, 0);
            setTotal(totalSum ? totalSum : 0);
            setCards(newCards);
        } else {
            context?.getClients('stats', ['reservado', 'em recolha', 'recolhido', 'em entrega', 'entregue', 'em movimento']);
        }
    }, [date, context?.clients, search, selectBrand, context]);

    function handleExport() {
        const updatedCards = cards.map((card: any) => {
            const updatedExtraServices = card.extraServices.map((service: any) => service.pt).join(', ');
            return {
                ...card,
                extraServices: updatedExtraServices
            };
        });
        exportExcel(updatedCards, formatDate(date));
    }

    if (!context) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <button onClick={() => navigate('/home')} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='flex flex-col justify-center w-full gap-6 py-20 cinco:py-8'>
                <h1 className='text-[1.2rem] font-mybold text-[var(--primary)] text-center'>CAIXA</h1>
                <DatePainel />
                <p className='text-center text-[var(--primary)] font-mybold'>TOTAL: {total}€</p>
                <div className='flex flex-col items-center w-full gap-2'>
                    <p className='text-left text-[var(--primary)] font-bold w-full'>Filtrar:</p>
                    <select
                        name="brand"
                        value={selectBrand}
                        className="select"
                        onChange={(e) => setSelectBrand(e.target.value)}
                    >
                        <option value="all">Todas as marcas</option>
                        <option value="airpark">Airpark</option>
                        <option value="redpark">Redpark</option>
                        <option value="skypark">Skypark</option>
                    </select>
                    <input value={search} onChange={(e) => setSearch(e.target.value)} type="text" placeholder='| PESQUISA' className='input' />
                </div>
                <div className='w-full px-20'>
                    {(type === 'Admin' || type === 'SuperVisor') && <button onClick={handleExport} className='w-full text-[1rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-black flex gap-2 items-center justify-center'>
                        EXPORTAR
                        <img src={DownloadIcon} alt="icon" className='w-6' />
                    </button>}
                </div>
                {
                    cards && cards.length > 0 &&
                    <p className='w-full font-bold text-center'>Total: {cards.length} reserva{cards.length > 1 && 's'}</p>
                }
                <div className='flex flex-col w-full gap-2'>
                    <p className='font-mybold'>Lista de Transações</p>
                    {
                        isLoading ? <p className='text-center'>Loading...</p> : cards && cards.length > 0 ?
                            cards.slice(0, 50).map((card: any, index: any) => {
                                return (
                                    <Card
                                        key={index}
                                        client={card}
                                        href={"caixa"}
                                        type={"caixa"}
                                    />
                                );
                            })
                            : <p className='text-center'>Nenhuma reserva encontrada</p>
                    }
                </div>
            </div>
        </>
    );
}
