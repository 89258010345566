import React, { createContext, useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, getDoc, query, where } from 'firebase/firestore';
import testData from '../content/jsons/clients.json';

export const ClientsContext = createContext();

export const ClientsProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [clients, setClients] = useState([]);
  const [parks, setParks] = useState([]);
  const [settings, setSettings] = useState(null);
  const [users, setUsers] = useState(null);

  const fetchInfo = async () => {
    // Parks
    try {
      let cityData = {};
      const citiesDocRef = doc(db, 'settings', 'cities');
      const citiesDocSnap = await getDoc(citiesDocRef);
      if (citiesDocSnap.exists()) {
        const citiesData = citiesDocSnap.data();
        for (const brand of citiesData['faro']) {
          if (!user?.parks.includes(brand.toLowerCase())) continue;
          // Parks
          const brandDocSnap = await getDoc(doc(db, 'faro', brand));
          if (!brandDocSnap.exists()) continue;
          const brandData = brandDocSnap.data()?.parks;
          let parksData = {};
          for (const park of brandData) {
            parksData[park.name] = park;
          }
          cityData[brand] = parksData;
        }
      }
      cityData = Object.keys(cityData).filter((ele) => user?.parks.includes(ele.toLowerCase())).reduce((obj, key) => {
        obj[key] = cityData[key];
        return obj;
      }, {});
      if (!Object.keys(cityData).length) setParks({});
      else setParks(cityData);
    } catch (error) {
      console.error("Fetch error:", error);
    }

    // Settings
    const allSettings = {};
    let settingsDocRef = doc(db, 'settings', 'tipoDeCancelamento');
    let settingsDocSnap = await getDoc(settingsDocRef);
    if (settingsDocSnap.exists()) {
      const settingsData = settingsDocSnap.data();
      allSettings['tipoDeCancelamento'] = settingsData;
    }
    settingsDocRef = doc(db, 'settings', 'tipoDePagamento');
    settingsDocSnap = await getDoc(settingsDocRef);
    if (settingsDocSnap.exists()) {
      const settingsData = settingsDocSnap.data();
      allSettings['tipoDePagamento'] = settingsData;
    }
    settingsDocRef = doc(db, 'settings', 'tipoDeOcorrencia');
    settingsDocSnap = await getDoc(settingsDocRef);
    if (settingsDocSnap.exists()) {
      const settingsData = settingsDocSnap.data();
      allSettings['tipoDeOcorrencia'] = settingsData;
    }
    settingsDocRef = doc(db, 'settings', 'procedimentos');
    settingsDocSnap = await getDoc(settingsDocRef);
    if (settingsDocSnap.exists()) {
      const settingsData = settingsDocSnap.data();
      allSettings['procedimentos'] = settingsData;
    }
    settingsDocRef = await getDoc(doc(db, 'settings', 'cities'));
    if (settingsDocRef.exists()) {
      const settingsData = settingsDocRef.data();
      allSettings['cities'] = Object.keys(settingsData);
      allSettings['brands'] = settingsData.faro;
    }
    settingsDocRef = await getDoc(doc(db, 'settings', 'externalParks'));
    if (settingsDocRef.exists()) {
      const settingsData = settingsDocRef.data();
      allSettings['externalParks'] = settingsData.all || [];
    }
    setSettings(allSettings);

    // Users
    if (user?.type === 'Admin') {
      const usersRef = collection(db, 'users-faro');
      const usersQuerySnapshot = await getDocs(usersRef);
      const usersData = usersQuerySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUsers(usersData);
    }
  };

  async function getClients(key, filtros) {
    try {
      // setClients(testData);

      let reservas = [];
      if (!settings) return;
      for (const brand of settings['brands']) {
        if (!user?.parks.includes(brand.toLowerCase())) continue;
        // Clients
        const clientsRef = collection(db, `faro/${brand}/clients`);
        const q = query(clientsRef, where(key, 'in', filtros));
        const querySnapshot = await getDocs(q);
        const brandBooks = querySnapshot.docs.map(doc => ({
          idClient: doc.id,
          ...doc.data()
        }));
        reservas = [...reservas, ...brandBooks];
      }
      reservas = reservas.filter((ele) => user?.parks.includes((ele?.parkBrand).toLowerCase()));
      setClients([...clients, ...reservas]);
    } catch (error) {
      console.log('Error getting documents: ', error);
    }
  }

  useEffect(() => {
    if (user && user?.type !== 'inactive')
      fetchInfo();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ClientsContext.Provider value={{
      user,
      setUser,
      users,
      setUsers,
      clients,
      setClients,
      parks,
      setParks,
      settings,
      setSettings,
      getClients,
      fetchInfo
    }}>
      {children}
    </ClientsContext.Provider>
  );
};
