import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DownloadIcon from '../../content/imgs/icons/download.png';
import { db } from '../../firebase';
import { collection, doc, getDoc, getDocs, orderBy, query } from 'firebase/firestore';
import { ClientsContext } from '../../contexts/Context';
import Dialog from '../components/alertDialog';
import { updateClient } from '../../server';
import { exportExcel } from '../components/export';
import Loading from '../components/loading';
import { jsPDF } from 'jspdf';

export default function ClientPage() {
    const { id = 0 } = useParams();
    const context = useContext(ClientsContext);
    const navigate = useNavigate();

    const [parkingOptions, setParkingOptions] = useState<any>(null);
    const [deliveryOptions, setDeliveryOptions] = useState<any>(null);
    const [parking, setParking] = useState<any>(null);
    const [delivery, setDelivery] = useState<any>(null);
    const [extraServices, setExtraServices] = useState<any>(null);
    const [newCheckIn, setNewCheckIn] = useState('');
    const [newCheckOut, setNewCheckOut] = useState('');
    const [newCarInfo, setNewCarInfo] = useState('');
    const [newLicensePlate, setNewLicensePlate] = useState('');

    const [history, setHistory] = useState<any>(null);
    const [index, setIndex] = useState(0);
    const [edit, setEdit] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const [type, setType] = useState('');


    useEffect(() => {
        if (context?.user) {
            setType(context?.user.type);
        }
    }, [context?.user]);

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        if (history && history[index] && parkingOptions && deliveryOptions) {
            setParking(parkingOptions.find((ele: any) => ele.pt === history[0].parkingType));
            setDelivery(deliveryOptions.find((ele: any) => ele.pt === history[0].deliveryName));
            setExtraServices(history[0].extraServices);
            setNewCarInfo(history[0]?.carInfo || '');
            setNewLicensePlate(history[0]?.licensePlate || '');
            setNewCheckIn(`${(history[0].checkIn).split('/')[2].split(', ')[0]}-${(history[0].checkIn).split('/')[1]}-${(history[0].checkIn).split('/')[0]}`);
            setNewCheckOut(`${(history[0].checkOut).split('/')[2].split(', ')[0]}-${(history[0].checkOut).split('/')[1]}-${(history[0].checkOut).split('/')[0]}`);
        }
    }, [history, index, parkingOptions, deliveryOptions]);

    useEffect(() => {
        const fetchData = async () => {
            const cityCollection = collection(db, history[0]?.city);
            let docRef = doc(cityCollection, (history[0]?.parkBrand).toLowerCase());
            let docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setParkingOptions(docSnap.data().parkingType);
            }
            docRef = doc(cityCollection, 'general');
            docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setDeliveryOptions(docSnap.data().delivery);
            }
        };
        if (history && history[0] && history[0]?.city && history[0]?.parkBrand) {
            fetchData();
        }
    }, [history]);

    useEffect(() => {
        async function getClientCard() {
            if (!context?.clients || context?.clients.length === 0
                || !context?.clients.some((client:any) => client?.idClient === id.toString())) {
                    context?.getClients('idClient', [id.toString()]);
                    return ;
                }
            let data = (context?.clients).filter((client: any) => (client?.idClient).toString() === id.toString());
            data = data[0];
            const historyCollectionRef = collection(db, `${data?.city}/${data?.parkBrand.toLowerCase()}/clients/${id}/history`);
            const q = query(historyCollectionRef, orderBy("__name__"));
            getDocs(q).then((querySnapshot: any) => {
                const historyDocuments = querySnapshot.docs.map((doc: any) => {
                    return {
                        ...doc.data()
                    };
                });
                setHistory(historyDocuments.reverse())
            }).catch((error) => {
                console.error("Error getting documents: ", error);
            });
        }
        getClientCard();
    }, [id, context?.clients, context]);

    function formatDate(isoDateString: string) {
        const date = new Date(isoDateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        if (day === 'NaN') return 'Campo vazio'
        return `${day}/${month}/${year}, ${hours}:${minutes}`;
    }

    function getSimpleDate(date: string) {
        const day = date.split('/')[0];
        const month = date.split('/')[1];
        if (day === 'NaN') return 'Erro'
        return `${day}/${month}`;
    }

    function getExtraServices(language: string) {
        let newExtraServices: { pt: string; price: number; checked: boolean, type: number }[] = [];
        extraServices.forEach((service: any) => {
            newExtraServices.push({
                pt: service[language] || "",
                price: service?.price || 0,
                checked: service?.checked || false,
                type: service?.type || 0
            });
        });
        return newExtraServices || [];
    }

    function getDateFormat(date: string) {
        const dd = date.split('-')[2];
        const mm = date.split('-')[1];
        const yyyy = date.split('-')[0];

        return `${dd}/${mm}/${yyyy}`;
    }

    function getNewBookingPrice() {
        const calculateDays = (start: string, end: string): number => {
            if (!start || !end) return 0;
            const startDate = new Date(start);
            const endDate = new Date(end);
            const timeDiff = endDate.getTime() - startDate.getTime() + 1;
            const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
            return days;
        };
        let result = 0;
        // Delivery
        if (delivery && !isNaN(parseInt(delivery.price)))
            result = result + parseInt(delivery.price);
        // Parking
        if (parking && !isNaN(parseInt(parking.price))) {
            const days = calculateDays(newCheckIn, newCheckOut);
            const parkingCost = days * parseInt(parking.price);
            result = result + parkingCost;
        }
        // Extra Services
        if (extraServices) {
            (extraServices).forEach((service: any) => {
                if (!isNaN(parseInt(service.price)))
                    result = result + parseInt(service.price);
            })
        }
        if (isNaN(result)) return 'Erro a calcular preço';
        else return result;
    }

    function handleExport() {
        const updatedCards = history.map((card: any) => {
            const updatedExtraServices = card.extraServices.map((service: any) => service.pt).join(', ');
            return {
                ...card,
                extraServices: updatedExtraServices
            };
        });
        exportExcel(updatedCards, history[0].idClient);
    }

    async function saveChanges() {
        if (history[0]?.stats === 'entregue') {
            await openDialog("Não é possível alterar estes campos. Carro já foi entregue.", () => { });
            return;
        }
        if (!parking || !newCarInfo || !newLicensePlate ||
            (!extraServices || extraServices.length === 0) ||
            !newCheckIn || !newCheckOut) {
            await openDialog("Por favor, preencha todos os campos.", () => { });
            return;
        }
        const data = { ...history[0] };
        data['parkingType'] = parking.pt || "";
        data['parkingPrice'] = parking.price || "";
        data['deliveryName'] = delivery && delivery.pt ? delivery.pt : "";
        data['extraServices'] = getExtraServices('pt');
        data['bookingPrice'] = getNewBookingPrice();
        data['licensePlate'] = newLicensePlate;
        data['carInfo'] = newCarInfo;
        data['checkIn'] = `${getDateFormat(newCheckIn)}, ${history[0].checkIn.split(', ')[1]}`
        data['checkOut'] = `${getDateFormat(newCheckOut)}, ${history[0].checkOut.split(', ')[1]}`
        data['actionUser'] = context?.user.email || 'unknown';
        data['actionDate'] = formatDate((new Date()).toISOString())
        data['action'] = 'Alteração na consulta';
        try {
            updateClient(context, navigate, data, context?.user.id || 'unknown', 'Alteração da reserva', openDialog);
        } catch (error) {
            console.error('Error updating document:', error);
        }
    }

    if (!history || (edit && !parking && !delivery)) {
        return (
            <Loading />
        )
    }

    if (!history.length) {
        return (
            <div className='flex items-center justify-center w-screen h-screen'>
                <button onClick={() => navigate(-1)} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
                Reserva sem histórico
            </div>
        )
    }

    return (
        <>
            <button onClick={() => navigate(-1)} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='flex flex-col justify-center w-full gap-6 py-20 cinco:py-8'>
                <h1 className='text-[1.2rem] font-mybold text-[var(--primary)] text-center'>RESERVA {id}</h1>
                <div className='relative text-center flex gap-10 text-[var(--primary)] font-mybold justify-center'>
                    {!edit && <button className={`cursor-pointer text-[1.2rem] font-bold ${index === history.length - 1 && 'opacity-0'}`} onClick={() => { if (index !== history.length - 1) setIndex((prev) => prev + 1) }}>&lt;&lt;</button>}
                    <div className="inline-block">
                        {
                            index !== history.length - 1 ?
                                getSimpleDate(history[index]?.actionDate)
                                :
                                `Book`
                        }
                    </div>
                    {!edit && <button className={`cursor-pointer text-[1.2rem] font-bold ${index === 0 && 'opacity-0'}`} onClick={() => { if (index !== 0) setIndex((prev) => prev - 1) }}>&gt;&gt;</button>}
                </div>
                {
                    index !== history.length - 1 ?
                        <>
                            <p className="font-bold">Detalhes desta alteração:</p>
                            <div className="flex items-center gap-4">
                                <label className="w-1/3">
                                    Utilizador:
                                </label>
                                <div className="w-2/3">
                                    {history[index]?.actionUser || "Campo vazio"}
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <label className="w-1/3">
                                    Ação:
                                </label>
                                <div className="w-2/3">
                                    {history[index]?.action || "Campo vazio"}
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <label className="w-1/3">
                                    Hora:
                                </label>
                                <div className="w-2/3">
                                    {history[index]?.actionDate || "Campo vazio"}
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="flex items-center gap-4">
                                <label className="w-1/3 font-bold">
                                    Data da reserva:
                                </label>
                                <div className="w-2/3">
                                    {history[index]?.bookingDate || "Campo vazio"}
                                </div>
                            </div>
                        </>
                }
                <div className="w-full h-[1px] bg-[grey]"></div>
                <p className="font-bold">Detalhes da reserva:</p>
                {/* Marca */}
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        Alocação:
                    </label>
                    <div className="w-2/3">
                        {history[index]?.alocation || "Campo vazio"}
                    </div>
                </div>
                {/* Marca */}
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        Marca:
                    </label>
                    <div className="w-2/3">
                        {history[index]?.parkBrand || "Campo vazio"}
                    </div>
                </div>
                {/* City */}
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        City:
                    </label>
                    <div className="w-2/3 capitalize">
                        {history[index]?.city || "Campo vazio"}
                    </div>
                </div>
                {/* Parking */}
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        Parking:
                    </label>
                    {
                        edit ?
                            <select
                                name="parking"
                                id="parking"
                                value={parking.pt}
                                className="w-2/3 form-select bg-[transparent] px-2 py-2 h-10 border-2 rounded-full border-black"
                                onChange={(e) => setParking(parkingOptions.find((option: any) => option.pt === e.target.value))}
                            >
                                <option value="">Selecione uma opção</option>
                                {parkingOptions && parkingOptions?.map((ele: any, index: number) => (
                                    <option key={index} value={ele.pt} className="text-black bg-white">
                                        {`${ele.pt} (${ele?.price}€)`}
                                    </option>
                                ))}
                            </select>
                            :
                            <div className="w-2/3">
                                {history[index]?.parkingType || "Campo vazio"}
                            </div>
                    }
                </div>
                {/* Delivery Options */}
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        Entrega em:
                    </label>
                    {
                        edit ?
                            <select
                                name="delivery"
                                id="delivery"
                                value={delivery?.pt || ""}
                                className="w-2/3 form-select bg-[transparent] px-2 py-2 h-10 border-2 rounded-full border-black"
                                onChange={(e) => setDelivery(deliveryOptions.find((option: any) => option.pt === e.target.value))}
                            >
                                <option value="">Selecione uma opção</option>
                                {deliveryOptions && deliveryOptions?.map((ele: any, index: number) => (
                                    <option key={index} value={ele.pt} className="text-black bg-white">
                                        {`${ele.pt} (${ele?.price}€)`}
                                    </option>
                                ))}
                            </select>
                            :
                            <div className="w-2/3">
                                {history[index]?.deliveryName || "Campo vazio"}
                            </div>
                    }
                </div>
                <div className="flex items-center gap-4">
                        <label className="w-1/3">
                            Preço da recolha/entrega:
                        </label>
                        <div className="w-2/3">
                            {history[index]?.deliveryPrice + '€' || "Campo vazio"}
                        </div>
                    </div>
                {
                    <div className="flex items-center gap-4">
                        <label className="w-1/3">
                            Serviços extra:
                        </label>
                        <div className='flex flex-col h-full gap-2'>
                            {
                                extraServices && extraServices.length !== 0 ? extraServices.map((ele: any, index: number) => {
                                    if (ele.type === -1) return null;
                                    return (
                                        <div key={index} className='flex flex-col h-full gap-2'>
                                            <div className={'flex items-center h-full gap-4'}>
                                                <div onClick={() => {
                                                    if (edit) {
                                                        const updatedExtraServices = extraServices.map((service: any, idx: number) => {
                                                            if (idx === index)
                                                                return { ...service, checked: !service.checked };
                                                            return service;
                                                        });
                                                        setExtraServices(updatedExtraServices);
                                                    }
                                                }} className={`rounded-[5px] border-2 border-black w-5 aspect-square cursor-pointer ${ele.checked ? 'bg-[var(--green)]' : 'bg-[red]'}`}></div>
                                                {
                                                    edit ?
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={ele.pt}
                                                                onChange={(e) => {
                                                                    const updatedExtraServices = [...extraServices];
                                                                    updatedExtraServices[index].pt = e.target.value;
                                                                    setExtraServices(updatedExtraServices);
                                                                }}
                                                                className="input !w-1/2"
                                                            />
                                                            <input
                                                                type="number"
                                                                value={ele.price}
                                                                onChange={(e) => {
                                                                    const updatedExtraServices = [...extraServices];
                                                                    updatedExtraServices[index].price = e.target.value;
                                                                    setExtraServices(updatedExtraServices);
                                                                }}
                                                                className="input !p-1 !w-[3rem] !rounded-[10px]"
                                                            />€
                                                        </>
                                                        :
                                                        <p>{ele.pt} {ele.price}€{index !== extraServices.length - 1 && ','}</p>
                                                }
                                            </div>
                                            {edit && (
                                                <div className='flex justify-end'>
                                                    <button
                                                        onClick={() => {
                                                            const updatedExtraServices = extraServices.filter((_: any, idx: number) => idx !== index);
                                                            setExtraServices(updatedExtraServices);
                                                        }}
                                                        className="bg-[var(--red)] w-6 h-6 text-white rounded-full"
                                                    >
                                                        -
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    );
                                }) :
                                    <p className='w-full text-center'>Nenhum resultado</p>
                            }
                        </div>
                    </div>
                }
                {
                    edit &&
                    <div className="flex justify-end w-full">
                        <button
                            className="bg-[var(--primary)] w-6 h-6 text-white rounded-full"
                            onClick={() => {
                                const newExtraService = {
                                    type: 0,
                                    pt: 'Novo serviço',
                                    price: 0,
                                    checked: false,
                                };
                                setExtraServices((prev: any[]) => [...prev, newExtraService]);
                            }}
                        >
                            +
                        </button>
                    </div>
                }
                {/* Check-in Date */}
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        Data de entrada:
                    </label>
                    {
                        edit ?
                            <>
                                <input
                                    type="date"
                                    id="checkinDate"
                                    value={newCheckIn}
                                    name="newcheckinDate"
                                    className="w-1/2 form-input bg-[transparent] px-6 py-[5px] sete:py-2 border-2 rounded-full border-black"
                                    onChange={(e) => setNewCheckIn(e.target.value)}
                                />
                                {(history[index]?.checkIn).split(', ')[1]}
                            </>
                            :
                            <div className="w-2/3">
                                {history[index]?.checkIn || "Campo vazio"}
                            </div>
                    }
                </div>
                {/* Departure Date */}
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        Data de saída:
                    </label>
                    {
                        edit ?
                            <>
                                <input
                                    type="date"
                                    id="checkinDate"
                                    value={newCheckOut}
                                    name="checkinDate"
                                    className="w-1/2 form-input bg-[transparent] px-6 py-[5px] sete:py-2 border-2 rounded-full border-black"
                                    onChange={(e) => setNewCheckOut(e.target.value)}
                                />
                                {(history[index]?.checkOut).split(', ')[1]}
                            </>
                            :
                            <div className="w-2/3">
                                {history[index]?.checkOut || "Campo vazio"}
                            </div>
                    }
                </div>
                {/* License Plate */}
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        Matrícula:
                    </label>
                    {
                        edit ?
                            <>
                                <input
                                    type="text"
                                    id="licensePlate"
                                    value={newLicensePlate}
                                    name="newlicensePlate"
                                    className="w-1/2 form-input bg-[transparent] px-6 py-[5px] sete:py-2 border-2 rounded-full border-black"
                                    onChange={(e) => setNewLicensePlate(e.target.value)}
                                />
                            </>
                            :
                            <div className="w-2/3">
                                {history[index]?.licensePlate.toUpperCase() || "Campo vazio"}
                            </div>
                    }
                </div>
                {/* Booking Price */}
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        Método de pagamento:
                    </label>
                    <div className="w-2/3">
                        {history[index]?.paymentMethod || "Campo vazio"}
                    </div>
                </div>
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        Preço da reserva:
                    </label>
                    <div className="w-2/3">
                        {history[index]?.bookingPrice ? `${history[index]?.bookingPrice}€` : "Campo vazio"}
                    </div>
                </div>
                {
                    history[index]?.acerto &&
                    <div className="flex items-center gap-4">
                        <label className="w-1/3">
                            Acerto:
                        </label>
                        <div className="w-2/3">
                            {history[index]?.correction + '€' || "Campo vazio"}
                        </div>
                    </div>
                }
                <div className="w-full h-[1px] bg-[grey]"></div>
                <p className="font-bold">Detalhes pessoais:</p>
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        Carro:
                    </label>
                    {
                        edit ?
                            <>
                                <input
                                    type="text"
                                    id="carInfo"
                                    value={newCarInfo}
                                    name="newcarInfo"
                                    className="w-1/2 form-input bg-[transparent] px-6 py-[5px] sete:py-2 border-2 rounded-full border-black"
                                    onChange={(e) => setNewCarInfo(e.target.value)}
                                />
                            </>
                            :
                            <div className="w-2/3">
                                {history[index]?.carInfo || "Campo vazio"}
                            </div>
                    }
                </div>
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        Nome:
                    </label>
                    <p>{history[index]?.name || "Campo vazio"}</p>
                </div>
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        Apelido:
                    </label>
                    <p>{history[index]?.lastname || "Campo vazio"}</p>
                </div>
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        Nº Voo:
                    </label>
                    <p>{history[index]?.returnFlight || "Campo vazio"}</p>
                </div>
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        Telemóvel:
                    </label>
                    <a className='hover:underline text-[var(--primary)]' href={`tel:${history[index]?.phoneNumber}`}>{history[index]?.phoneNumber || "Campo vazio"}</a>
                </div>
                <div className="flex items-center gap-4">
                    <label className="w-1/3">
                        E-mail:
                    </label>
                    <a href={`mailto:${history[index]?.email}`} className='hover:underline text-[var(--primary)] cursor-pointer'>{history[index]?.email || "Campo vazio"}</a>
                </div>
                {
                    history[index]?.taxName &&
                    <>
                        <div className="flex items-center gap-4">
                            <label className="w-1/3">
                                Nome para fatura:
                            </label>
                            <p>{history[index]?.taxName}</p>
                        </div>
                        <div className="flex items-center gap-4">
                            <label className="w-1/3">
                                Número de contribuinte:
                            </label>
                            <p>{history[index]?.taxNumber}</p>
                        </div>
                    </>
                }
                {
                    history[index]?.stats !== 'reservado' &&
                    <>
                        <div className="w-full h-[1px] bg-[grey]"></div>
                        <p className="font-bold">Local do Carro:</p>
                        <div className="flex items-center gap-4">
                            <label className="w-1/3">
                                Park:
                            </label>
                            <p>{history[index]?.park || "Campo vazio"}</p>
                        </div>
                        <div className="flex items-center gap-4">
                            <label className="w-1/3">
                                Fila:
                            </label>
                            <p>{history[index]?.row || "Campo vazio"}</p>
                        </div>
                        <div className="flex items-center gap-4">
                            <label className="w-1/3">
                                Lugar:
                            </label>
                            <p>{history[index]?.spot || "Campo vazio"}</p>
                        </div>
                        <div className="flex items-center gap-4">
                            <label className="w-1/3">
                                Condutor Recolha:
                            </label>
                            <p>{history[index]?.condutorRecolha || "Campo vazio"}</p>
                        </div>
                        <div className="flex items-center gap-4">
                            <label className="w-1/3">
                                Condutor Entrega:
                            </label>
                            <p>{history[index]?.condutorEntrega || "Campo vazio"}</p>
                        </div>
                        <div className="flex items-center gap-4">
                            <label className="w-1/3">
                                Condutor Movimentação:
                            </label>
                            <p>{history[index]?.condutorMovimentacao || "Campo vazio"}</p>
                        </div>
                        <div className="flex items-center gap-4">
                            <label className="w-1/3">
                                Última localização:
                            </label>
                            <a href={history[index]?.carLocation} className='hover:underline text-[var(--primary)]'>{history[index]?.carLocation ? "Ver no mapa" : "Campo vazio"}</a>
                        </div>
                    </>
                }
                <div className="w-full h-[1px] bg-[grey]"></div>
                <div className="flex items-center gap-4">
                    <label className="w-1/3 font-bold">
                        Estado:
                    </label>
                    <p className='uppercase'>{history[index]?.stats}</p>
                </div>
                {
                    history[index]?.ocorrenceType && type === 'Admin' &&
                    <>
                        <div className="flex items-center gap-4">
                            <label className="w-1/3">
                                Ocorrência:
                            </label>
                            <p>{history[index]?.ocorrenceType}</p>
                        </div>
                        {history[index]?.ocorrence !== '' &&
                            <div className="flex items-center gap-4">
                                <label className="w-1/3">
                                    Obs:
                                </label>
                                <p>{history[index]?.ocorrence}</p>
                            </div>}
                    </>
                }
                {
                    history[index]?.cancelType &&
                    <>
                        <div className="flex items-center gap-4">
                            <label className="w-1/3">
                                Motivo de cancelamento:
                            </label>
                            <p>{history[index]?.cancelType}</p>
                        </div>
                        {history[index]?.cancel !== '' &&
                            <div className="flex items-center gap-4">
                                <label className="w-1/3">
                                    Obs:
                                </label>
                                <p>{history[index]?.cancel}</p>
                            </div>}
                    </>
                }
                {
                    index === 0 &&
                    <>
                        <div className='grid grid-cols-2 gap-4 '>
                            {(type === 'Admin' || type === 'SuperVisor') && history[index]?.stats === 'reservado' && <button onClick={() => navigate(`/recolhas/${id}`)} className='w-full text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-full  py-2 text-white uppercase'>RECOLHER</button>}
                            {(type === 'Admin' || type === 'SuperVisor') && history[index]?.stats === 'recolhido' && <button onClick={() => navigate(`/entregas/${id}`)} className='w-full text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-full  py-2 text-white uppercase'>ENTREGAR</button>}
                            {type === 'Admin' && history[index]?.stats === 'recolhido' && <button onClick={() => navigate(`/movimentacao/${id}`)} className='w-full text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-full  py-2 text-white uppercase'>Movimentar</button>}
                            {(type === 'Admin' || type === 'SuperVisor') && history[index]?.stats !== 'cancelado' && <button onClick={() => navigate(`/caixa/${id}`)} className='w-full text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-full  py-2 text-white uppercase'>CAIXA</button>}
                            <button onClick={() => navigate(`/ocorrencias/${id}`)} className='w-full text-[1rem] bg-[var(--orange)] border-[1px] hover:border-[1px] hover:border-black rounded-full  py-2 text-black uppercase'>OCORRÊNCIA</button>
                            {/* <button onClick={printClientInfo} className='w-full text-[1rem] bg-[var(--yellow)] border-[1px] hover:border-[1px] hover:border-black rounded-full  py-2 text-black uppercase'>IMPRIMIR</button> */}
                            {type === 'Admin' && <button onClick={handleExport} className='w-full text-[1rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-black flex gap-2 items-center justify-center'>
                                EXPORTAR
                                <img src={DownloadIcon} alt="icon" className='w-6' />
                            </button>}
                        </div>
                        <div className='flex gap-4 pt-4 border-t-2 border-black'>
                            {index === 0 && <button onClick={() => navigate('edit')} className='w-full text-[1rem] bg-[var(--yellow)] border-[1px] hover:border-[1px] hover:border-black rounded-full  py-2 text-black uppercase'>{edit ? 'CANCELAR' : 'ALTERAR'}</button>}
                            {edit && <button onClick={saveChanges} className='w-full text-[1rem] bg-[var(--orange)] border-[1px] hover:border-[1px] hover:border-black rounded-full  py-2 text-black uppercase'>SALVAR</button>}
                        </div>
                    </>
                }
            </div>
            <Dialog
                message={dialogMessage}
                opened={dialogOpen}
                onDialog={closeDialog}
                onClose={dialogOnClose}
            />
        </>
    );
}

