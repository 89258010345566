import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sos from '../content/imgs/icons/sos.png';
import Phone from '../content/imgs/icons/phone-b.png';
import Email from '../content/imgs/icons/email-b.png';
import { updateClient } from '../server';
import Dialog from './components/alertDialog';
import { ClientsContext } from '../contexts/Context';
import emailjs from 'emailjs-com';
import Loading from './components/loading';

export default function Ocorrencias() {
    const { id = "0" } = useParams();
    const context = useContext(ClientsContext);
    const navigate = useNavigate();
    const [client, setClient] = useState<any>(null);
    const [listaDeOcorrencias, setListaDeOcorrencias] = useState<any>(null);
    const [clientId, setClientId] = useState('');
    const [ocorrencia, setOcorrencia] = useState('');
    const [text, setText] = useState('');
    const [location, setLocation] = useState<any>(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        async function getClientCard() {
            const data = (context?.clients).filter((client: any) => (client?.idClient).toString() === id.toString())
            if (data.length === 1) setClient(data[0]);
            setClientId(id);
        }
        if (id !== "0")
            getClientCard();
    }, [id, context?.clients]);

    useEffect(() => {
        if (context?.settings)
            setListaDeOcorrencias(context?.settings?.tipoDeOcorrencia?.all);
    }, [context?.settings]);

    useEffect(() => {
        if (!navigator.geolocation) {
            alert('Geolocation is not supported by your browser');
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            },
            () => {
                alert('Unable to retrieve your location');
            }
        );
    }, []);

    function formatDate(isoDateString: string) {
        const date = new Date(isoDateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year}, ${hours}:${minutes}`;
    }

    async function submit() {
        if (clientId !== "" && (ocorrencia === '' || text === '')) {
            openDialog('Preencha todos os campos.', () => null)
            return;
        }
        let data: any = {};
        if (client) {
            data = { ...client };
        } 
        data['ocorrenceType'] = ocorrencia;
        data['ocorrence'] = text;
        data['actionUser'] = context?.user.email || 'unknown';
        data['actionDate'] = formatDate((new Date()).toISOString());
        data['action'] = 'Ocorrência';
        data['carLocation'] = `https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`;
        // Email params
        const emailParams = {
            ocorrenciaType: ocorrencia,
            ocorrencia: text,
            idClient: id,
            carLocation: `https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`
        }
        try {
            updateClient(context, navigate, data, context?.user.id || 'unknown', 'Ocorrência', openDialog);
            emailjs.send('service_qctwd7l', 'template_qp0cfzk', emailParams, 'jeStFGZ-400kFvT_-');
        } catch (error) {
            console.error('Error updating document:', error);
        }
    }

    if (!listaDeOcorrencias) {
        return (
            <Loading/>
        )
    }

    return (
        <>
            <button onClick={() => navigate(-1)} className='absolute top-8 left-[5vw] quatro:left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='flex flex-col justify-center w-full gap-6 py-20 cinco:py-8'>
                <h1 className='text-[1.2rem] font-mybold text-[var(--primary)] text-center'>OCORRÊNCIAS</h1>
                <div className='flex flex-col items-center justify-center'>
                    {
                        id !== "0" &&
                            <>
                                <p className='text-[1.2rem] font-mybold text-[var(--primary)] text-center'>REGISTO: {id}</p>
                                {
                                    client && client?.alocation &&
                                    <p className='text-[1.2rem] font-mybold text-[var(--primary)] text-center'>ALOCAÇÃO: {client?.alocation}</p>
                                }
                            </>
                    }
                </div>
                <select
                    name="ocorrencia"
                    value={ocorrencia}
                    className="select"
                    onChange={(e) => setOcorrencia(e.target.value)}
                >
                    <option value="">Selecionar</option>
                    {
                        listaDeOcorrencias.slice().reverse().map((ele: string, index: number) => {
                            return (
                                <option key={index} value={ele}>{ele}</option>
                            )
                        })
                    }
                </select>
                <textarea className='w-full resize-none h-[15rem] border-2 border-black rounded-[25px] p-4' placeholder='| Texto' name="ocorrencia" cols={30} rows={10} value={text} onChange={(e) => setText(e.target.value)}></textarea>
                <div className='flex flex-col items-center w-full gap-4'>
                    <button onClick={() => window.open(process.env.REACT_APP_OCORRENCIA_LINK, "_blank")} className='w-1/2 text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-[10px] py-2 text-white'>VIDEO/PHOTO</button>
                    <button onClick={submit} className='w-1/2 text-[1rem] bg-[var(--orange)] border-[1px] hover:border-[1px] hover:border-black rounded-[10px] py-2 text-white'>SUBMETER</button>
                </div>
                <div className='flex items-center justify-between w-full gap-4 mt-4'>
                    <a href="tel:935625800"><img src={Phone} alt="phone" className='w-[4rem] h-[4rem] cursor-pointer' /></a>
                    <a href="tel:112"><img src={Sos} alt="sos" className='w-[8rem] cursor-pointer' /></a>
                    <a href="mailto:jorgetabuada@airpark.pt"><img src={Email} alt="email" className='w-[4rem] h-[4rem] cursor-pointer' /></a>
                </div>
            </div>
            <Dialog
                message={dialogMessage}
                opened={dialogOpen}
                onDialog={closeDialog}
                onClose={dialogOnClose}
            />
        </>
    );
}

