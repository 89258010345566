import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DateProvider } from "./contexts/DateContext";
import { auth } from "./firebase";
import { useContext, useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase";
import { ClientsContext } from "./contexts/Context";
import { signOut } from "firebase/auth";

import Login from "./pages/login";
import Signup from "./pages/signup";
import Home from "./pages/home";
import NotFoundPage from "./pages/notFound";
import Entregas from "./pages/entregas/entregas";
import Entrega from "./pages/entregas/entrega";
import Caixas from "./pages/caixas/caixas";
import Caixa from "./pages/caixas/caixa";
import Movimentacoes from "./pages/movimentacao/movimentacoes";
import Movimentacao from "./pages/movimentacao/movimentacao";
import Ocorrencias from "./pages/ocorrencias";
import Parques from "./pages/parques/parques";
import Parque from "./pages/parques/parque";
import ParqueExterno from "./pages/parques/parqueExterno";
import Recolhas from "./pages/recolhas/recolhas";
import Procedimento from "./pages/procedimentos";
import Recolha from "./pages/recolhas/recolha";
import Cancelar from "./pages/cancelar";
import Users from "./pages/users/users";
import EditUser from "./pages/users/createUsers";
import UserPage from "./pages/users/userPage";
import UserHistory from "./pages/users/userHistory";
import Consulta from "./pages/consulta/consulta";
import Import from "./pages/import";
import ClientPage from "./pages/consulta/clientPage";
import Settings from "./pages/settings/settings";
import SettingsParques from "./pages/settings/parques";
import SettingsParquesAdd from "./pages/settings/addParque";
import SettingsParquesAddExtern from "./pages/settings/addExternParque";
import SettingsProcedimentos from "./pages/settings/procedimentos";
import SettingsMarcas from "./pages/settings/marcas";
import WebsiteHome from "./pages/website/home";
import WebsiteForm from "./pages/website/form";
import WebsiteTerms from "./pages/website/terms";
import WebsitePartners from "./pages/website/partners";
import Website from "./pages/website/website";
import Loading from "./pages/components/loading";
import EditClient from "./pages/consulta/editClient";
// import Premios from './pages/premios';

function App() {
  const context = useContext(ClientsContext);

  const [isLogged, setIsLogged] = useState(false);
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (context?.user) {
      setType(context?.user?.type);
    }
  }, [context?.user]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setIsLogged(!!user);
      if (user) {
        const userInfo = await fetchUserInfo();
        if (!userInfo) {
          await signOut(auth);
          localStorage.clear();
          alert("Usuário não encontrado.");
          return;
        }
        context?.setUser(userInfo);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setIsLogged(false);
      }
    });
    return () => unsubscribe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUserInfo = async () => {
    const usersRef = collection(db, "users-faro");
    const q = query(usersRef, where("email", "==", auth?.currentUser?.email?.split('@')[0]));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return null;
    const userDoc = querySnapshot.docs[0];
    const user = {...userDoc.data(), id: userDoc.id};
    return user;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full px-[5vw] quatro:px-[8vw] flex justify-center">
      <div className="w-full cinco:w-[25rem] flex flex-col items-center gap-10">
        <>
          <DateProvider>
            <BrowserRouter>
              <Routes>
                <Route index element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                {isLogged && (
                  <>
                    <Route path="/home" element={<Home />} />
                    <Route path="/movimentacao" element={<Movimentacoes />} />
                    <Route
                      path="/movimentacao/:id"
                      element={<Movimentacao />}
                    />
                    <Route path="/ocorrencias/:id" element={<Ocorrencias />} />
                    <Route path="/parques" element={<Parques />} />
                    <Route path="/parques/:brand/:name" element={<Parque />} />
                    <Route
                      path="/parques/externo/:name"
                      element={<ParqueExterno />}
                    />
                    <Route path="/procedimentos" element={<Procedimento />} />
                    {/* <Route path="/premios" element={<Premios />} /> */}
                    {type === "Admin" && (
                      <>
                        <Route path="/users" element={<Users />} />
                        <Route path="/users/create" element={<EditUser />} />
                        <Route path="/user/:username" element={<UserPage />} />
                        <Route
                          path="/user/:username/history"
                          element={<UserHistory />}
                        />
                        <Route path="/caixa" element={<Caixas />} />
                        <Route path="/caixa/:id" element={<Caixa />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route
                          path="/settings/procedimentos"
                          element={<SettingsProcedimentos />}
                        />
                        <Route
                          path="/settings/marcas"
                          element={<SettingsMarcas />}
                        />
                        <Route
                          path="/settings/parques"
                          element={<SettingsParques />}
                        />
                        <Route
                          path="/settings/parques/add"
                          element={<SettingsParquesAdd />}
                        />
                        <Route
                          path="/settings/parques/extern/add"
                          element={<SettingsParquesAddExtern />}
                        />
                        <Route path="/website" element={<Website />} />
                        <Route path="/website/home" element={<WebsiteHome />} />
                        <Route
                          path="/website/partners"
                          element={<WebsitePartners />}
                        />
                        <Route
                          path="/website/terms"
                          element={<WebsiteTerms />}
                        />
                        <Route path="/website/form" element={<WebsiteForm />} />
                        <Route path="/import" element={<Import />} />
                        <Route path="/recolhas" element={<Recolhas />} />
                        <Route path="/recolhas/:id" element={<Recolha />} />
                        <Route path="/entregas" element={<Entregas />} />
                        <Route path="/entregas/:id" element={<Entrega />} />
                        <Route path="/cancelar/:id" element={<Cancelar />} />
                        <Route path="/consulta" element={<Consulta />} />
                        <Route path="/consulta/:id" element={<ClientPage />} />
                        <Route path="/consulta/:id/edit" element={<EditClient />} />
                      </>
                    )}
                    {type === "SuperVisor" && (
                      <>
                        <Route path="/users" element={<Users />} />
                        <Route path="/users/create" element={<EditUser />} />
                        <Route path="/user/:username" element={<UserPage />} />
                        <Route
                          path="/user/:username/history"
                          element={<UserHistory />}
                        />
                        <Route path="/caixa" element={<Caixas />} />
                        <Route path="/caixa/:id" element={<Caixa />} />
                        <Route path="/recolhas" element={<Recolhas />} />
                        <Route path="/recolhas/:id" element={<Recolha />} />
                        <Route path="/entregas" element={<Entregas />} />
                        <Route path="/entregas/:id" element={<Entrega />} />
                        <Route path="/cancelar/:id" element={<Cancelar />} />
                        <Route path="/consulta" element={<Consulta />} />
                        <Route path="/consulta/:id" element={<ClientPage />} />
                      </>
                    )}
                    {type === "Chefe" && (
                      <>
                        <Route path="/caixa" element={<Caixas />} />
                        <Route path="/caixa/:id" element={<Caixa />} />
                        <Route path="/recolhas" element={<Recolhas />} />
                        <Route path="/recolhas/:id" element={<Recolha />} />
                        <Route path="/entregas" element={<Entregas />} />
                        <Route path="/entregas/:id" element={<Entrega />} />
                        <Route path="/cancelar/:id" element={<Cancelar />} />
                        <Route path="/consulta" element={<Consulta />} />
                        <Route path="/consulta/:id" element={<ClientPage />} />
                      </>
                    )}
                    {type === "Condutor" && (
                      <>
                        <Route path="/recolhas" element={<Recolhas />} />
                        <Route path="/recolhas/:id" element={<Recolha />} />
                        <Route path="/entregas" element={<Entregas />} />
                        <Route path="/entregas/:id" element={<Entrega />} />
                        <Route path="/cancelar/:id" element={<Cancelar />} />
                        {/* <Route path="/consulta" element={<Consulta />} />
												<Route path="/consulta/:id" element={<ClientPage />} /> */}
                      </>
                    )}
                  </>
                )}
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </BrowserRouter>
          </DateProvider>
        </>
      </div>
    </div>
  );
}

export default App;
