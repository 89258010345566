import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import Dialog from '../components/alertDialog';
import { ClientsContext } from '../../contexts/Context';

export default function Parque() {

    const navigate = useNavigate();
    const context = useContext(ClientsContext);

    const [parkData, setParkData] = useState<any>({})

    const [city, setCity] = useState('faro');
    void setCity;
    const [brand, setBrand] = useState('');
    const [data, setData] = useState<any>(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        if (context?.parks)
            setParkData(context.parks);
    }, [context.parks]);

    useEffect(() => {
        if (city && brand && parkData[city][brand])
            setData(parkData[city][brand]);
    }, [brand, city, parkData]);

    async function edit() {
        if (data?.baseValue < 0) {
            openDialog('Preços não podem ser menor que 0.', () => null)
            return;
        }
        for (let i = 0; i < data?.parkingType.length; i++) {
            const ele = data?.parkingType[i];
            if (ele.price < 0) {
                openDialog('Preços não podem ser menor que 0.', () => null);
                return;
            }
        }
        for (let i = 0; i < data?.extraServices.length; i++) {
            const ele = data?.extraServices[i];
            if (ele.price < 0) {
                openDialog('Preços não podem ser menor que 0.', () => null);
                return;
            }
        }
        if (!window.confirm(`Tem a certeza que quer editar as definições da marca '${brand}'?`)) {
            return;
        }
        try {
            const brandDocRef = doc(db, city, brand);
            const brandDocSnap = await getDoc(brandDocRef);
            if (!brandDocSnap.exists()) {
                throw new Error(`Document ${brand} does not exist!`);
            }
            // Save the updated parks array back to Firestore
            await updateDoc(brandDocRef, {
                baseValue: data?.baseValue,
                extraServices: data?.extraServices,
                parkingType: data?.parkingType,
            });

            openDialog(`Marca '${brand}' editada com sucesso.`, () => { });

        } catch (error) {
            console.error("Error editing marca: ", error);
            openDialog(`Erro ao editar o marca '${brand}'.`, () => {});
        }
    }

    return (
        <div className='w-full pw-[8vw] flex justify-center'>
            <button onClick={() => navigate(-1)} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='w-full cinco:w-[25rem] flex flex-col items-center gap-10 py-20 cinco:py-8'>
                <h1 className='text-[var(--primary)] font-mybold text-[1.2rem] cinco:text-[1.5rem] uppercase'>MARCAS</h1>
                <div className='cinco:px-[3rem] w-full flex flex-col gap-6'>
                    {/* <button onClick={() => navigate('/settings/parques/add')} className='w-full text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-white uppercase'>+ Nova Marca</button> */}
                    {
                        city &&
                        <div className='flex gap-4'>
                            <p><span className='font-semibold'>Cidade:</span> {city}</p>
                        </div>
                    }
                    {
                        city &&
                        <div className="flex flex-col gap-2">
                            <label htmlFor="brand-input" className='font-semibold'>Marca:</label>
                            <select
                                name="parque"
                                value={brand}
                                className="uppercase select"
                                onChange={(e) => setBrand(e.target.value)}
                            >
                                <option value="">Selecionar</option>
                                {
                                    parkData && city && parkData[city] && Object.keys(parkData[city]).map((park: string, index: number) => {
                                        return (
                                            <option key={index} value={park} className='uppercase'>{park}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    }
                    {
                        brand &&
                        <>
                            <div className='flex items-center gap-4'>
                                <label className='font-semibold w-[10rem]'>Valor base:</label>
                                <input
                                    type="number"
                                    placeholder='Preço por dia'
                                    value={data?.baseValue}
                                    onChange={(e) => {
                                        setData((prev: any) => ({
                                            ...prev,
                                            baseValue: parseInt(e.target.value)
                                        }));
                                    }}
                                    className='w-[3rem] input'
                                />€
                            </div>
                            <div className='flex flex-col gap-4'>
                                <label className='font-semibold'>Tipos de parque:</label>
                                {
                                    data?.parkingType && data?.parkingType.length !== 0 ? data?.parkingType.map((ele: any, index: number) => {
                                        return (
                                            <div key={index} className='flex items-center justify-between gap-8'>
                                                <label className='w-[20rem]'>{ele.pt}:</label>
                                                <input
                                                    type="number"
                                                    placeholder='Preço por dia'
                                                    value={ele?.price}
                                                    onChange={(e) => {
                                                        setData((prev: any) => ({
                                                            ...prev,
                                                            parkingType: prev.parkingType.map((item: any, idx: number) =>
                                                                idx === index ? { ...item, price: parseInt(e.target.value) } : item
                                                            ),
                                                        }));
                                                    }}
                                                    className='w-[3rem] input'
                                                />€
                                            </div>
                                        )
                                    }) : <p className='w-full text-center'>Nenhum tipo de parque</p>
                                }
                            </div>
                            <div className='flex flex-col gap-4'>
                                <div className='flex items-center justify-between'>
                                    <label className='font-semibold'>Serviços Extra:</label>
                                    <button onClick={() => setData((prev: any) => ({
                                        ...prev,
                                        extraServices: [
                                            ...prev.extraServices,
                                            { en: "", pt: "", price: 0 }
                                        ]
                                    }))} className='px-6 text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-1 text-white uppercase'>+ add</button>
                                </div>
                                {
                                    data?.extraServices && data?.extraServices.length !== 1 ? data?.extraServices.map((ele: any, index: number) => {
                                        if (index === 0) return null;
                                        return (
                                            <div key={index} className='flex flex-col justify-center gap-4 items-between'>
                                                <div className='flex items-center gap-4'>
                                                    <p className='w-[10rem]'>Português</p>
                                                    <input
                                                        type="text"
                                                        placeholder='Serviço'
                                                        value={ele.pt}
                                                        onChange={(e) => {
                                                            setData((prev: any) => ({
                                                                ...prev,
                                                                extraServices: prev.extraServices.map((item: any, idx: number) =>
                                                                    idx === index ? { ...item, pt: e.target.value } : item
                                                                ),
                                                            }));
                                                        }} className='input'
                                                    />
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <p className='w-[10rem]'>Inglês</p>
                                                    <input
                                                        type="text"
                                                        placeholder='Service'
                                                        value={ele.en}
                                                        onChange={(e) => {
                                                            setData((prev: any) => ({
                                                                ...prev,
                                                                extraServices: prev.extraServices.map((item: any, idx: number) =>
                                                                    idx === index ? { ...item, en: e.target.value } : item
                                                                ),
                                                            }));
                                                        }} className='input'
                                                    />
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <p className='w-[10rem]'>Preço</p>
                                                    <input
                                                        type="number"
                                                        placeholder='Preço'
                                                        value={ele?.price}
                                                        onChange={(e) => {
                                                            setData((prev: any) => ({
                                                                ...prev,
                                                                extraServices: prev.extraServices.map((item: any, idx: number) =>
                                                                    idx === index ? { ...item, price: parseInt(e.target.value) } : item
                                                                ),
                                                            }));
                                                        }}
                                                        className='w-[3rem] input'
                                                    />
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <p className='w-[10rem]'>Tipo</p>
                                                    <select value={ele?.type} onChange={(e) => {
                                                        setData((prev: any) => ({
                                                            ...prev,
                                                            extraServices: prev.extraServices.map((item: any, idx: number) =>
                                                                idx === index ? { ...item, type: parseInt(e.target.value) } : item
                                                            ),
                                                        }));
                                                    }}
                                                        className='input'>
                                                        <option value="0">Lavagem</option>
                                                        <option value="1">Análise</option>
                                                        <option value="2">Elétrico</option>
                                                        <option value="-1">Outro</option>
                                                    </select>
                                                </div>
                                                <div onClick={() => setData((prev: any) => ({
                                                    ...prev,
                                                    extraServices: prev.extraServices.filter((ele: any, idx: number) => idx !== index)
                                                }))} className='px-6 py-1 w-[8rem] bg-[var(--red)] rounded-full flex justify-center items-center cursor-pointer text-white uppercase font-semibold'>Remover</div>

                                                {index !== data?.extraServices.length - 1 && <hr />}
                                            </div>
                                        )
                                    }) : <p className='w-full text-center'>Nenhum serviço extra</p>

                                }
                            </div>
                            <div className='flex flex-col gap-4'>
                                <label className='font-semibold'>Alocações:</label>
                                {
                                    data?.alocations && Object.keys(data?.alocations).map((ele: string, index: number) => {
                                        return (
                                            <div key={index} className='flex items-center justify-between gap-8'>
                                                <label className='w-[20rem] capitalize'>{ele}:</label>
                                                <div className='flex gap-2'>
                                                    <p>{data?.alocations[ele].min}</p>
                                                    <p>-</p>
                                                    <p>{data?.alocations[ele].max}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>
                    }
                    <div className='flex w-full gap-4'>
                        <button onClick={edit} className='w-full text-[1rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-black'>SALVAR</button>
                    </div>
                </div>
                <Dialog
                    message={dialogMessage}
                    opened={dialogOpen}
                    onDialog={closeDialog}
                    onClose={dialogOnClose}
                />
            </div>
        </div>
    );
}

