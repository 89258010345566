import React, { useRef } from 'react';
import { useDate } from '../../contexts/DateContext';

export default function DatePainel() {
    const { date, setDate } = useDate();
    const dateInputRef = useRef<HTMLInputElement>(null);

    function formatDate(date: Date) {
        let day = date.getDate().toString().padStart(2, '0');
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        return `${day}/${month}`;
    }

    function prevDay() {
        setDate(new Date(date.setDate(date.getDate() - 1)));
    }

    function nextDay() {
        setDate(new Date(date.setDate(date.getDate() + 1)));
    }

    function pickDate() {
        dateInputRef.current?.showPicker();
    }

    function handleDateChange(event: React.ChangeEvent<HTMLInputElement>) {
        const selectedDate = new Date(event.target.value);
        setDate(selectedDate);
    }

    return (
        <div className='relative text-center flex gap-10 text-[var(--primary)] font-mybold justify-center'>
            <span className='cursor-pointer text-[1.2rem] font-bold' onClick={prevDay}>&lt;&lt;</span>
            <div className="inline-block">
                <span className="text-[var(--primary)] font-mybold cursor-pointer" onClick={pickDate}>
                    Dia {formatDate(date)}
                </span>
                <input
                    type="date"
                    ref={dateInputRef}
                    onChange={handleDateChange}
                    className="absolute top-4 left-[6rem] w-1 opacity-0"
                    value={date.toISOString().split('T')[0]}
                />
            </div>
            <span className='cursor-pointer text-[1.2rem] font-bold' onClick={nextDay}>&gt;&gt;</span>
        </div>
    );
}
