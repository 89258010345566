import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { collection, doc, getDoc } from "firebase/firestore";
import { ClientsContext } from "../../contexts/Context";
import Dialog from "../components/alertDialog";
import { updateClient } from "../../server";
import Loading from "../components/loading";

export default function EditClient() {
  const { id = 0 } = useParams();
  const context = useContext(ClientsContext);
  const navigate = useNavigate();

  const [parkingOptions, setParkingOptions] = useState<any>(null);
  const [deliveryOptions, setDeliveryOptions] = useState<any>(null);
  const [parking, setParking] = useState<any>(null);
  const [extraServices, setExtraServices] = useState<any>(null);

  const [client, setClient] = useState<any>(null);
  const [bookingPrice, setBookingPrice] = useState<string>("-1");
  const [checkInDate, setCheckInDate] = useState<string>("");
  const [checkOutDate, setCheckOutDate] = useState<string>("");
  const [checkInTime, setCheckInTime] = useState<string>("");
  const [checkOutTime, setCheckOutTime] = useState<string>("");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogOnClose, setDialogOnClose] = useState("");

  const [type, setType] = useState("");

  useEffect(() => {
    if (context?.user) {
      setType(context?.user.type);
    }
  }, [context?.user]);

  const openDialog = (message: string, onClose: any) => {
    setDialogMessage(message);
    setDialogOpen(true);
    setDialogOnClose(() => onClose);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (client && parkingOptions && deliveryOptions) {
      setParking(
        parkingOptions.find((ele: any) => ele.pt === client.parkingType)
      );
      setExtraServices(client.extraServices);
    }
  }, [client, parkingOptions, deliveryOptions]);

  useEffect(() => {
    const fetchData = async () => {
      const cityCollection = collection(db, client?.city);
      let docRef = doc(cityCollection, (client?.parkBrand).toLowerCase());
      let docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setParkingOptions(docSnap.data().parkingType);
      }
      docRef = doc(cityCollection, "general");
      docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setDeliveryOptions(docSnap.data().delivery);
      }
    };
    if (client && client?.city && client?.parkBrand) {
      fetchData();
    }
  }, [client]);

  useEffect(() => {
    async function getClientCard() {
      if (
        !context?.clients ||
        context?.clients.length === 0 ||
        !context?.clients.some(
          (client: any) => client?.idClient === id.toString()
        )
      ) {
        context?.getClients("idClient", [id.toString()]);
        return;
      }
      let data = (context?.clients).filter(
        (client: any) => (client?.idClient).toString() === id.toString()
      );
      data = data[0];
      setClient(data);
      setCheckInDate(formatDateForInput(data?.checkIn.split(", ")[0]));
      setCheckInTime(data?.checkIn.split(", ")[1]);
      setCheckOutDate(formatDateForInput(data?.checkOut.split(", ")[0]));
      setCheckOutTime(data?.checkOut.split(", ")[1]);
      setBookingPrice(data?.bookingPrice);
    }
    getClientCard();
  }, [id, context?.clients, context]);

  useEffect(() => {
    function getNewBookingPrice() {
      const calculateDays = (start: string, end: string): number => {
        if (!start || !end) return 0;
        const startDate = new Date(start);
        const endDate = new Date(end);
        const timeDiff = endDate.getTime() - startDate.getTime() + 1;
        const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        return days;
      };
      let result = 0;
      // Delivery
      if (client?.deliveryPrice && !isNaN(parseInt(client?.deliveryPrice)))
        result = result + parseInt(client?.deliveryPrice);
      // Parking
      if (parking && !isNaN(parseInt(parking.price))) {
        const days = calculateDays(checkInDate, checkOutDate);
        const parkingCost = days * parseInt(parking.price);
        result = result + parkingCost;
      }
      // Extra Services
      if (extraServices) {
        extraServices.forEach((service: any) => {
          if (!isNaN(parseInt(service.price)))
            result = result + parseInt(service.price);
        });
      }
      if (isNaN(result)) setBookingPrice("Erro");
      else return setBookingPrice(result.toString());
    }
    if (client && parking && extraServices && checkInDate && checkOutDate)
      getNewBookingPrice();
  }, [
    client,
    client?.deliveryPrice,
    parking,
    extraServices,
    checkInDate,
    checkOutDate,
  ]);

  function formatDate(isoDateString: string) {
    const date = new Date(isoDateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    if (day === "NaN") return "Campo vazio";
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  }

  function formatDateForInput(date: string) {
    const [day, month, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }

  function getExtraServices(language: string) {
    let newExtraServices: {
      pt: string;
      price: number;
      checked: boolean;
      type: number;
    }[] = [];
    extraServices.forEach((service: any) => {
      newExtraServices.push({
        pt: service[language] || "",
        price: service?.price || 0,
        checked: service?.checked || false,
        type: service?.type || 0,
      });
    });
    return newExtraServices || [];
  }

  function formatDateString(date: string) {
    const [year, month, day] = date.split("-");
    return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
  }

  function formatTimeString(time: string) {
    return time.padStart(5, "0");
  }

  function getCheckIn() {
    if (!checkInDate || !checkInTime) return "Campo vazio";
    const formattedDate = formatDateString(checkInDate);
    const formattedTime = formatTimeString(checkInTime);
    return `${formattedDate}, ${formattedTime}`;
  }

  function getCheckOut() {
    if (!checkOutDate || !checkOutTime) return "Campo vazio";
    const formattedDate = formatDateString(checkOutDate);
    const formattedTime = formatTimeString(checkOutTime);
    return `${formattedDate}, ${formattedTime}`;
  }

  async function saveChanges() {
    if (
      !client ||
      !parking ||
      !checkInDate ||
      !checkOutDate ||
      !checkInTime ||
      !checkOutTime ||
      !client?.name ||
      !client?.lastname ||
      !client?.phoneNumber ||
      !client?.email ||
      !client?.licensePlate ||
      !client?.carInfo ||
      !client?.deliveryName
    ) {
      await openDialog("Por favor, preencha todos os campos.", () => {});
      return;
    }
    const data = { ...client };
    data["parkingType"] = parking.pt || "";
    data["parkingPrice"] = parking.price || "";
    data["deliveryName"] = client?.deliveryName;
    data["deliveryPrice"] = client?.deliveryPrice || "10";
    data["extraServices"] = getExtraServices("pt");
    data["bookingPrice"] = bookingPrice;
    data["licensePlate"] = client.licensePlate;
    data["carInfo"] = client.carInfo;
    data["checkIn"] = getCheckIn();
    data["checkOut"] = getCheckOut();
    data["stats"] = client.stats;
    data["returnFlight"] = client.returnFlight || "";
    data["paymentMethod"] = client.paymentMethod || "";
    data["taxName"] = client.taxName || "";
    data["taxNumber"] = client.taxNumber || "";
    data["email"] = client.email;
    data["phoneNumber"] = client.phoneNumber;
    data["name"] = client.name;
    data["lastname"] = client.lastname;
    data["alocation"] = client.alocation || "";
    data["actionUser"] = context?.user.email || "unknown";
    data["actionDate"] = formatDate(new Date().toISOString());
    data["action"] = "Alteração na consulta";
    try {
      updateClient(
        context,
        navigate,
        data,
        context?.user.id || "unknown",
        "Alteração da reserva",
        openDialog
      );
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }

  if (!parking) {
    return <Loading />;
  }

  return (
    <>
      <button
        onClick={() => navigate(-1)}
        className="absolute top-8 left-[8vw] font-mybold"
      >
        ⬅ VOLTAR
      </button>
      <div className="flex flex-col justify-center w-full gap-6 py-20 cinco:py-8">
        <h1 className="text-[1.2rem] font-mybold text-[var(--primary)] text-center">
          EDITAR RESERVA {id}
        </h1>
        <div className="w-full h-[1px] bg-[grey]"></div>
        <p className="font-bold">Detalhes da reserva:</p>
        {/* Marca */}
        <div className="flex items-center gap-4">
          <label className="w-1/3">Alocação:</label>
          <div className="w-2/3">
            <input
              className="input"
              type="text"
              disabled={type !== "Admin" && type !== "Chefe"}
              value={client?.alocation || ""}
              onChange={(e) =>
                setClient((prevClient: any) => ({
                  ...prevClient,
                  alocation: e.target.value,
                }))
              }
            />
          </div>
        </div>
        {/* Marca */}
        <div className="flex items-center gap-4">
          <label className="w-1/3">Marca:</label>
          <div className="w-2/3">
            <input
              className="input"
              type="text"
              disabled
              value={client?.parkBrand || ""}
              onChange={(e) =>
                setClient((prevClient: any) => ({
                  ...prevClient,
                  parkBrand: e.target.value,
                }))
              }
            />
          </div>
        </div>
        {/* City */}
        <div className="flex items-center gap-4">
          <label className="w-1/3">City:</label>
          <div className="w-2/3 capitalize">
            <input
              className="input"
              type="text"
              disabled
              value={client?.city || ""}
              onChange={(e) =>
                setClient((prevClient: any) => ({
                  ...prevClient,
                  city: e.target.value,
                }))
              }
            />
          </div>
        </div>
        {/* Parking */}
        <div className="flex items-center gap-4">
          <label className="w-1/3">Parking:</label>
          <select
            name="parking"
            id="parking"
            value={parking.pt}
            className="w-2/3 form-select bg-[transparent] px-2 py-2 h-10 border-2 rounded-full border-black"
            onChange={(e) =>
              setParking(
                parkingOptions.find(
                  (option: any) => option.pt === e.target.value
                )
              )
            }
          >
            <option value="">Selecione uma opção</option>
            {parkingOptions &&
              parkingOptions?.map((ele: any, index: number) => (
                <option
                  key={index}
                  value={ele.pt}
                  className="text-black bg-white"
                >
                  {`${ele.pt} (${ele?.price}€)`}
                </option>
              ))}
          </select>
        </div>
        {/* Delivery Options */}
        <div className="flex items-center gap-4">
          <label className="w-1/3">Entrega em:</label>
          <div className="flex gap-2">
            <input
              type="text"
              placeholder="Entrega em"
              value={client?.deliveryName || ""}
              onChange={(e) =>
                setClient((prevClient: any) => ({
                  ...prevClient,
                  deliveryName: e.target.value,
                }))
              }
              className="input"
              disabled={type !== "Admin" && type !== "Chefe"}
            />
            <input
              type="number"
              placeholder="€"
              value={client?.deliveryPrice || ""}
              onChange={(e) =>
                setClient((prevClient: any) => ({
                  ...prevClient,
                  deliveryPrice: e.target.value,
                }))
              }
              className="input !w-20"
              disabled={type !== "Admin" && type !== "Chefe"}
            />
          </div>
        </div>
        <div className="flex items-center gap-4">
          <label className="w-1/3">Serviços extra:</label>
          <div className="flex flex-col h-full gap-2">
            {extraServices && extraServices.length !== 0 ? (
              extraServices.map((ele: any, index: number) => {
                if (ele.type === -1) return null;
                return (
                  <div key={index} className="flex flex-col h-full gap-2">
                    <div className={"flex items-center h-full gap-4"}>
                      <div
                        onClick={() => {
                          if (true) {
                            const updatedExtraServices = extraServices.map(
                              (service: any, idx: number) => {
                                if (idx === index)
                                  return {
                                    ...service,
                                    checked: !service.checked,
                                  };
                                return service;
                              }
                            );
                            setExtraServices(updatedExtraServices);
                          }
                        }}
                        className={`rounded-[5px] border-2 border-black w-5 aspect-square cursor-pointer ${
                          ele.checked ? "bg-[var(--green)]" : "bg-[red]"
                        }`}
                      ></div>
                      <>
                        <input
                          type="text"
                          value={ele.pt}
                          onChange={(e) => {
                            const updatedExtraServices = [...extraServices];
                            updatedExtraServices[index].pt = e.target.value;
                            setExtraServices(updatedExtraServices);
                          }}
                          className="input !w-1/2"
                          disabled={type !== "Admin" && type !== "Chefe"}
                        />
                        <input
                          type="number"
                          value={ele.price}
                          onChange={(e) => {
                            const updatedExtraServices = [...extraServices];
                            updatedExtraServices[index].price = e.target.value;
                            setExtraServices(updatedExtraServices);
                          }}
                          className="input !p-1 !w-[3rem] !rounded-[10px]"
                          disabled={type !== "Admin" && type !== "Chefe"}
                        />
                        €
                      </>
                    </div>
                    <div className="flex justify-end">
                      <button
                        onClick={() => {
                          const updatedExtraServices = extraServices.filter(
                            (_: any, idx: number) => idx !== index
                          );
                          setExtraServices(updatedExtraServices);
                        }}
                        className="bg-[var(--red)] w-6 h-6 text-white rounded-full"
                      >
                        -
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="w-full text-center">Nenhum resultado</p>
            )}
          </div>
        </div>
        <div className="flex justify-end w-full">
          <button
            className="bg-[var(--primary)] w-6 h-6 text-white rounded-full"
            onClick={() => {
              const newExtraService = {
                type: 0,
                pt: "Novo serviço",
                price: 0,
                checked: false,
              };
              setExtraServices((prev: any[]) => [...prev, newExtraService]);
            }}
          >
            +
          </button>
        </div>
        {/* Check-in Date */}
        <div className="flex items-center gap-4">
          <label className="w-1/3">Data de entrada:</label>
          <input
            type="date"
            id="checkinDate"
            value={checkInDate}
            name="newcheckinDate"
            className="input"
            onChange={(e) => setCheckInDate(e.target.value)}
          />
          <input
            type="time"
            id="checkInTime"
            value={checkInTime}
            name="checkInTime"
            className="input"
            onChange={(e) => setCheckInTime(e.target.value)}
          />
        </div>
        {/* Departure Date */}
        <div className="flex items-center gap-4">
          <label className="w-1/3">Data de saída:</label>
          <input
            type="date"
            id="checkOutDate"
            value={checkOutDate}
            name="checkOutDate"
            className="input"
            onChange={(e) => setCheckOutDate(e.target.value)}
            disabled={type !== "Admin" && type !== "Chefe"}
          />
          <input
            type="time"
            id="checkOutTime"
            value={checkOutTime}
            name="checkOutTime"
            className="input"
            onChange={(e) => setCheckOutTime(e.target.value)}
            disabled={type !== "Admin" && type !== "Chefe"}
          />
        </div>
        {/* License Plate */}
        <div className="flex items-center gap-4">
          <label className="w-1/3">Matrícula:</label>
          <input
            type="text"
            id="licensePlate"
            value={client?.licensePlate}
            name="newlicensePlate"
            className="uppercase input"
            onChange={(e) =>
              setClient((prevClient: any) => ({
                ...prevClient,
                licensePlate: e.target.value.toUpperCase(),
              }))
            }
            disabled={type !== "Admin" && type !== "Chefe"}
          />
        </div>
        {/* Booking Price */}
        <div className="flex items-center gap-4">
          <label className="w-1/3">Método de pagamento:</label>
          <div className="w-2/3">
            <input
              className="input"
              type="text"
              value={client?.paymentMethod || ""}
              onChange={(e) =>
                setClient((prevClient: any) => ({
                  ...prevClient,
                  paymentMethod: e.target.value,
                }))
              }
              disabled={type !== "Admin" && type !== "Chefe"}
            />
          </div>
        </div>
        <div className="flex items-center gap-4">
          <label className="w-1/3">Preço da reserva:</label>
          <div className="w-2/3">
            <p>{bookingPrice}€</p>
          </div>
        </div>
        {client?.acerto && (
          <div className="flex items-center gap-4">
            <label className="w-1/3">Acerto:</label>
            <div className="w-2/3">
              <input
                className="input"
                type="text"
                value={client?.correction + "€" || ""}
                onChange={(e) =>
                  setClient((prevClient: any) => ({
                    ...prevClient,
                    correction: e.target.value,
                  }))
                }
                disabled={type !== "Admin" && type !== "Chefe"}
              />
            </div>
          </div>
        )}
        <div className="w-full h-[1px] bg-[grey]"></div>
        <p className="font-bold">Detalhes pessoais:</p>
        <div className="flex items-center gap-4">
          <label className="w-1/3">Carro:</label>
          <input
            type="text"
            id="carInfo"
            value={client?.carInfo}
            name="newcarInfo"
            className="input"
            onChange={(e) =>
              setClient((prevClient: any) => ({
                ...prevClient,
                carInfo: e.target.value,
              }))
            }
            disabled={type !== "Admin" && type !== "Chefe"}
          />
        </div>
        <div className="flex items-center gap-4">
          <label className="w-1/3">Nome:</label>
          <input
            className="input"
            type="text"
            value={client?.name || ""}
            onChange={(e) =>
              setClient((prevClient: any) => ({
                ...prevClient,
                name: e.target.value,
              }))
            }
            disabled={type !== "Admin" && type !== "Chefe"}
          />
        </div>
        <div className="flex items-center gap-4">
          <label className="w-1/3">Apelido:</label>
          <input
            className="input"
            type="text"
            value={client?.lastname || ""}
            onChange={(e) =>
              setClient((prevClient: any) => ({
                ...prevClient,
                lastname: e.target.value,
              }))
            }
            disabled={type !== "Admin" && type !== "Chefe"}
          />
        </div>
        <div className="flex items-center gap-4">
          <label className="w-1/3">Nº Voo Retorno:</label>
          <input
            className="input"
            type="text"
            value={client?.returnFlight || ""}
            onChange={(e) =>
              setClient((prevClient: any) => ({
                ...prevClient,
                returnFlight: e.target.value,
              }))
            }
            disabled={type !== "Admin" && type !== "Chefe"}
          />
        </div>
        <div className="flex items-center gap-4">
          <label className="w-1/3">Telemóvel:</label>
          <input
            className="input"
            type="text"
            value={client?.phoneNumber || ""}
            onChange={(e) =>
              setClient((prevClient: any) => ({
                ...prevClient,
                phoneNumber: e.target.value,
              }))
            }
            disabled={type !== "Admin" && type !== "Chefe"}
          />
        </div>
        <div className="flex items-center gap-4">
          <label className="w-1/3">E-mail:</label>
          <input
            className="input"
            type="text"
            value={client?.email || ""}
            onChange={(e) =>
              setClient((prevClient: any) => ({
                ...prevClient,
                email: e.target.value,
              }))
            }
            disabled={type !== "Admin" && type !== "Chefe"}
          />
        </div>
        {client?.taxName && (
          <>
            <div className="flex items-center gap-4">
              <label className="w-1/3">Nome para fatura:</label>
              <input
                className="input"
                type="text"
                value={client?.taxName}
                onChange={(e) =>
                  setClient((prevClient: any) => ({
                    ...prevClient,
                    taxName: e.target.value,
                  }))
                }
                disabled={type !== "Admin" && type !== "Chefe"}
              />
            </div>
            <div className="flex items-center gap-4">
              <label className="w-1/3">Número de contribuinte:</label>
              <input
                className="input"
                type="text"
                value={client?.taxNumber}
                onChange={(e) =>
                  setClient((prevClient: any) => ({
                    ...prevClient,
                    taxNumber: e.target.value,
                  }))
                }
                disabled={type !== "Admin" && type !== "Chefe"}
              />
            </div>
          </>
        )}
        <div className="w-full h-[1px] bg-[grey]"></div>
        <div className="flex items-center gap-4">
          <label className="w-1/3 font-bold">Estado:</label>
          <select
            name="estado"
            value={client?.stats}
            onChange={(e) => {
              setClient((prevClient: any) => ({
                ...prevClient,
                stats: e.target.value,
              }));
            }}
            className="input"
            disabled={type !== "Admin" && type !== "Chefe"}
          >
            <option value="reservado">Reservado</option>
            <option value="recolhido">Recolhido</option>
            <option value="em recolha">Em recolha</option>
            <option value="entregue">Entregue</option>
            <option value="em entrega">em entrega</option>
            <option value="cancelado">Cancelado</option>
          </select>
        </div>
        {client?.ocorrenceType && type === "Admin" && (
          <>
            <div className="flex items-center gap-4">
              <label className="w-1/3">Ocorrência:</label>
              <p>{client?.ocorrenceType}</p>
            </div>
            {client?.ocorrence !== "" && (
              <div className="flex items-center gap-4">
                <label className="w-1/3">Obs:</label>
                <p>{client?.ocorrence}</p>
              </div>
            )}
          </>
        )}
        {client?.cancelType && (
          <>
            <div className="flex items-center gap-4">
              <label className="w-1/3">Motivo de cancelamento:</label>
              <p>{client?.cancelType}</p>
            </div>
            {client?.cancel !== "" && (
              <div className="flex items-center gap-4">
                <label className="w-1/3">Obs:</label>
                <p>{client?.cancel}</p>
              </div>
            )}
          </>
        )}
        <div className="flex gap-4 pt-4 border-t-2 border-black">
          <button
            onClick={() => navigate(-1)}
            className="w-full text-[1rem] bg-[var(--yellow)] border-[1px] hover:border-[1px] hover:border-black rounded-full  py-2 text-black uppercase"
          >
            CANCELAR
          </button>
          <button
            onClick={saveChanges}
            className="w-full text-[1rem] bg-[var(--orange)] border-[1px] hover:border-[1px] hover:border-black rounded-full  py-2 text-black uppercase"
          >
            SALVAR
          </button>
        </div>
      </div>
      <Dialog
        message={dialogMessage}
        opened={dialogOpen}
        onDialog={closeDialog}
        onClose={dialogOnClose}
      />
    </>
  );
}
