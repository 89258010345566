import React, { useEffect, useState } from 'react';
import Logo from '../content/imgs/logos/multicar.png';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Dialog from './components/alertDialog';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';

export default function SignUp() {

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');


    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            if (user)
                navigate('/home');
        });
        return () => unsubscribe();
    }, [navigate]);

    const signup = async () => {
        if (name === '' || email === '' || password === '') {
            openDialog('Preencha todos os campos.', () => null)
            return;
        }
        await createUserWithEmailAndPassword(auth, `${email.toLowerCase()}@multipark.pt`, password)
            .then(async () => {
                try {
                    // Create user document in firestore
                    const usersRef = collection(db, "users-faro");
                    const newUserDocRef = await addDoc(usersRef, {
                        email: email.toLowerCase(),
                        name: name,
                        type: 'inactive',
                        points: 0,
                    });
                    const userId = newUserDocRef.id;
                    const now = new Date().toISOString();
                    const historyRef = collection(db, `users-faro/${userId}/history`);
                    const newHistoryDocRef = doc(historyRef, now);
                    await setDoc(newHistoryDocRef, {
                        email: email.toLowerCase(),
                        idClient: "0",
                        action: "Registo do user",
                        actionDate: now,
                        date: now,
                        points: 0,
                        type: 'inactive'
                    });
                    navigate('/home');
                } catch (err) {
                    console.error('Erro ao criar user: ', err);
                }
            })
            .catch(() => {
                openDialog('Email ou password inválidos. Password deve ter 6 caractéres no mínimo.', () => null)
                return;
            });
    }

    return (
        <>
            <img src={Logo} alt="logo" className='w-[10rem] cinco:w-[14rem] py-10' />
            <h1 className='text-[var(--primary)] font-mybold text-[1.2rem] cinco:text-[1.5rem]'>REGISTO</h1>
            <input type="text" value={name} placeholder='| Name' onChange={(e) => setName(e.target.value)} className="input" />
            <input type="text" value={email} placeholder='| Username' onChange={(e) => setEmail(e.target.value)} className="lowercase input" />
            <input type="password" value={password} placeholder='| Password' onChange={(e) => setPassword(e.target.value)} className="input" />
            <button onClick={signup} className='text-[1rem] border-[1px] border-[transparent] bg-[var(--primary)] hover:border-[1px] hover:border-black rounded-full  py-1 text-white px-10'>Registar</button>
            <p>Já tem conta? Faça o login <button onClick={() => navigate('/')} className='text-[var(--primary)] cursor-pointer hover:underline'>aqui</button></p>
            <Dialog
                message={dialogMessage}
                opened={dialogOpen}
                onDialog={closeDialog}
                onClose={dialogOnClose}
            />
        </>
    );
}

