import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loading() {
    return (
        <div className='bg-[var(--primary)] text-white flex items-center justify-center w-screen h-screen text-[1.5rem]'>
            <CircularProgress color="inherit" />
        </div>
    )
}

