
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';

async function UpdateClient(context, navigate, data, user, change, openDialog) {

    const now = (new Date()).toISOString();
    // Save client data
    try {
        const updatedClientRef = doc(db, `${data.city}/${data.parkBrand.toLowerCase()}/clients/`, data.idClient);
        await updateDoc(updatedClientRef, data);
    } catch (err) {
        console.error(err);
    }
    // Save user activity
    try {
        const newHistoryDocRef = doc(db, `users/${user}/history`, now);
        await setDoc(newHistoryDocRef, {
            idClient: data.idClient,
            alocation: data.alocation || 'Campo vazio',
            action: change,
            actionDate: data.actionDate,
        });
    } catch (err) {
        console.error(err);
    }
    // Save data in history
    try {
        const newHistoryDocRef = doc(db, `${data.city}/${data.parkBrand.toLowerCase()}/clients/${data.idClient}/history`, now);
        await setDoc(newHistoryDocRef, data).then(() =>
            openDialog(`${change} feita com sucesso.`, () => {
                navigate(-1);
            })
        );
    } catch (err) {
        openDialog('Erro ao atualizar a reserva', () => {
            navigate(-1);
        });
        console.error(err);
    }
    context?.setClients((prevClients) => {
        return prevClients.map((client) =>
            client.idClient === data.idClient ? data : client
        );
    });
}


export const updateClient = UpdateClient;
