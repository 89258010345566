import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from './components/loading';

import Card from './components/clientCard';

import { updateClient } from '../server';
import Dialog from './components/alertDialog';
import { ClientsContext } from '../contexts/Context';

export default function Cancelar() {
    const { id = 0 } = useParams();
    const [client, setClient] = useState<any>(null);
    const [listaDeMotivos, setListaDeMotivos] = useState<any>(null);
    const context = useContext(ClientsContext);
    const navigate = useNavigate();

    const [motivo, setMotivo] = useState('');
    const [obsv, setObsv] = useState('');
    const [condutor, setCondutor] = useState('');

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        async function getClientCard() {
            const data = (context?.clients).filter((client: any) => (client?.idClient).toString() === id.toString())
            if (data.length === 1) setClient(data[0]);
        }
        getClientCard();
    }, [id, context?.clients]);

    useEffect(() => {
        if (client) {
            setCondutor(context?.user?.email || "");
        }
    }, [client, context?.user.email]);

    useEffect(() => {
        if (context?.settings)
            setListaDeMotivos(context?.settings?.tipoDeCancelamento?.all)
    }, [context?.settings]);

    function formatDate(isoDateString: string) {
        const date = new Date(isoDateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year}, ${hours}:${minutes}`;
    }

    async function cancelar() {
        if (motivo === '') {
            openDialog('Preencha todos os campos.', () => null)
            return;
        }
        const data = { ...client };
        data['cancelType'] = motivo;
        data['cancel'] = obsv;
        data['stats'] = 'cancelado';
        data['actionUser'] = context?.user.email || 'unknown';
        data['actionDate'] = formatDate((new Date()).toISOString());
        data['action'] = 'Cancelamento';
        try {
            updateClient(context, navigate, data, context?.user.id || 'unknown', 'Cancelamento', openDialog);
        } catch (error) {
            console.error('Error updating document:', error);
        }
    }


    if (!client || !listaDeMotivos) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <button onClick={() => navigate(-1)} className='absolute top-8 left-[5vw] quatro:left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='flex flex-col justify-center w-full gap-6 py-20 cinco:py-8'>
                <h1 className='text-[1.2rem] font-mybold text-[var(--primary)] text-center'>CANCELAMENTOS</h1>
                <Card
                    client={client}
                    href={"recolhas"}
                    type={"recolhas"}
                />
                <div className='flex flex-col gap-4'>
                    <div className='flex flex-col gap-2'>
                        <p>Motivo:</p>
                        <select
                            name="motivo"
                            value={motivo}
                            className="select"
                            onChange={(e) => setMotivo(e.target.value)}
                        >
                            <option value="">Selecionar</option>
                            {
                                listaDeMotivos.slice().reverse().map((ele: string, index: number) => {
                                    return (
                                        <option key={index} value={ele}>{ele}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>Observações:</p>
                        <input value={obsv} onChange={(e) => setObsv(e.target.value)} placeholder='|' type="text" className='w-full px-3 py-2 border-2 border-black rounded-full' />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p>User:</p>
                        <input disabled value={condutor} onChange={(e) => setCondutor(e.target.value)} placeholder='|' type="text" className='w-full border-2 border-black rounded-full px-3 py-2 bg-[rgba(.5,.5,.5,.1)]' />
                    </div>
                </div>
                <button onClick={cancelar} className='w-full text-[1rem] bg-[var(--yellow)] border-[1px] hover:border-[1px] hover:border-black rounded-full  py-2 text-black'>CANCELAR</button>
            </div>
            <Dialog
                message={dialogMessage}
                opened={dialogOpen}
                onDialog={closeDialog}
                onClose={dialogOnClose}
            />
        </>
    );
}
