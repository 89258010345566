import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase';

export default function Website() {

    const navigate = useNavigate();
    const [texts, setTexts] = useState<any>(null);

    useEffect(() => {
        async function fetchData() {
            try {
                let docSnap = await getDoc(doc(db, 'website', 'home'));
                if (docSnap.exists()) setTexts(docSnap.data());
            } catch (error) {
                console.error("Error getting document: ", error);
            }
        }
        if (auth?.currentUser) fetchData();
    }, []);

    if (!texts) {
        return (
            <div className='w-screen h-screen flex justify-center items-center'>
                Loading...
            </div>
        )
    }

    return (
        <>
            <div className='w-full pw-[8vw] flex justify-center'>
                <button onClick={() => navigate('/home')} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
                <div className='w-full cinco:w-[25rem] flex flex-col items-center gap-10 py-20 cinco:py-8'>
                    <h1 className='text-[var(--primary)] font-mybold text-[1.2rem] cinco:text-[1.5rem] uppercase'>WEBSITE</h1>
                    <div className='grid grid-cols-2 gap-4 quatro:gap-4'>
                        <div onClick={() => navigate('/website/home')} className='uppercase text-white bg-[var(--primary)] rounded-[20px] w-[7rem] h-[5rem] cursor-pointer flex flex-col justify-center items-center hover:border-[3px] hover:border-black'>Home</div>
                        <div onClick={() => navigate('/website/form')} className='uppercase text-white bg-[var(--primary)] rounded-[20px] w-[7rem] h-[5rem] cursor-pointer flex flex-col justify-center items-center hover:border-[3px] hover:border-black'>Reservas</div>
                        <div onClick={() => navigate('/website/partners')} className='uppercase text-white bg-[var(--primary)] rounded-[20px] w-[7rem] h-[5rem] cursor-pointer flex flex-col justify-center items-center hover:border-[3px] hover:border-black'>Partners</div>
                        <div onClick={() => navigate('/website/terms')} className='uppercase text-white bg-[var(--primary)] rounded-[20px] w-[7rem] h-[5rem] cursor-pointer flex flex-col justify-center items-center hover:border-[3px] hover:border-black'>Terms</div>
                    </div>
                </div>
            </div>
        </>
    );
}

