import Phone from '../../content/imgs/icons/phone-w.png';
import Map from '../../content/imgs/icons/map-w.png';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function Card({ ...props }) {

    const navigate = useNavigate();
    const [client, setClient] = useState<any>(null);

    useEffect(() => {
        setClient(props?.client);
    }, [props?.client]);

    function selectInfo() {
        if (props.type === 'consulta' || props.type === 'caixa') return client?.stats;
        else if (props.type === 'entregas' || props.type === 'movimentacao' || props.type === "parque") return (client?.checkOut).replace(',', '');
        else if (client?.stats === 'em recolha') return client?.carInfo
        else return (client?.checkIn).replace(',', '');
    }

    function getBackgroundColor() {
        if (client?.parkBrand.toLowerCase() === 'airpark') return 'bg-[var(--primary)]';
        else if (client?.parkBrand.toLowerCase() === 'redpark') return 'bg-[var(--red)]';
        else if (client?.parkBrand.toLowerCase() === 'skypark') return 'bg-[var(--purple)]';
        else return 'bg-[var(--primary)]';
    }

    function getExtraServices() {
        if (client?.extraServices && client.extraServices.length > 0) {
            try {
                const done = client.extraServices?.every((service: any) => service.checked === true);
                return (
                    <div className='flex items-center gap-2 text-[.7rem]'>
                        <div>
                            <p>Serviços</p>
                            <p>Extra</p>
                        </div>
                        <div className={`${done ? 'bg-[var(--green)]' : 'bg-[red]'} w-3 h-3 rounded-full border-[1px] border-black`}></div>
                    </div>
                );
            } catch (error) {
                console.log(error);
            }
        }
        return null;
    }

    function getPark() {
        if (props.type === 'parque') {
            return `${client?.row ? `${client?.row || ""} - ${client?.spot || ""}` : "Sem lugar"}`;
        }
        if (client?.park.includes(':')) {
            return `${client?.park.split(':')[0]}, ${client?.park.split(':')[1]} ${client?.row ? `${client?.row || ""}:${client?.spot || ""}` : ""}`;
        }
    }

    if (!client) {
        return (
            <div className={`bg-[var(--primary)] rounded-[25px] p-2 quatro:p-4 w-full h-[10rem] flex text-white uppercase font-bold cursor-pointer gap-4 border-4 border-[transparent] hover:border-black text-[.9rem] quatro:text-[1rem]`}>
                <p>A carregar...</p>
            </div>
        )
    }

    return (
        <div onClick={() => (props.href ? navigate(`/${props.href}/${client?.idClient}`) : navigate(`/consulta/${client?.idClient}`))} className={`${getBackgroundColor()} rounded-[25px] p-2 quatro:p-4 w-full h-[10rem] flex text-white uppercase font-bold cursor-pointer gap-4 border-4 border-[transparent] hover:border-black text-[.8rem] small:text-[.9rem]`}>
            <div className='w-full h-full flex flex-col justify-between max-w-[6rem]'>
                <p className='text-white'>{selectInfo()}</p>
                <div>
                    <p className='truncate'>{client?.name && client?.name.split(' ')[0]}</p>
                    <p className='truncate'>{client?.lastname && client?.lastname.split(' ')[client?.lastname.split(' ').length - 1]}</p>
                </div>
            </div>
            <div className='flex flex-col justify-between w-[8rem] h-full'>
                <p className='quatro:max-w-[6rem] max-w-[5rem] overflow-hidden text-ellipsis'>
                    {client?.stats === 'reservado' || client?.stats === 'em recolha' ? client?.deliveryName :
                        client?.park ? getPark() : 'Sem parque'}
                </p>
                <div>
                    <p>Matricula:</p>
                    <p className='uppercase'>{client?.licensePlate}</p>
                </div>
            </div>
            <div className='flex flex-col items-end justify-between w-full h-full'>
                <div>
                    <p className='text-[var(--green)]'>{client?.alocation ? `AL: ${client?.alocation}` : `ID: ${client?.idClient}`}</p>
                </div>
                {
                    props.type === 'movimentacao' ? null :
                        props.type !== 'caixa' ?
                            <div className='flex flex-col gap-2'>
                                <div className='flex flex-col justify-end gap-1 small:flex-row'>
                                    {getExtraServices()}
                                </div>
                                <div className='flex gap-1 cinco:gap-3'>
                                    <img onClick={() => window.open(client?.deliveryLocation, '_blank')} src={Map} alt="icon" className='w-8' />
                                    <a href={`tel:${client?.phoneNumber}`}><img src={Phone} alt="icon" className='w-8 aspect-square' /></a>
                                </div>
                            </div>
                            :
                            <div className='text-[1.5rem]'>
                                <p>{client?.bookingPrice} €</p>
                            </div>
                }
            </div>
        </div >
    );
}

