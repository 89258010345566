import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import Dialog from '../components/alertDialog';
import Bin from '../../content/imgs/icons/bin.png';
import { ClientsContext } from '../../contexts/Context';

export default function Parque() {

    const navigate = useNavigate();
    const context = useContext(ClientsContext);

    const [city, setCity] = useState('faro');
    void setCity;
    const [parque, setParque] = useState<any>(null);
    const [parkData, setParkData] = useState<any>({})

    const [brand, setBrand] = useState('');
    const [parkName, setParkName] = useState('');
    const [newParkName, setNewParkName] = useState('');
    const [fila, setFila] = useState(0);
    const [lugares, setLugares] = useState(0);
    const [locationName, setLocationName] = useState('');
    const [location, setLocation] = useState('');
    const [unavailableSpots, setUnavailableSpots] = useState<any>(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        if (context?.parks)
            setParkData(context.parks);
    }, [context.parks]);

    function changeParque(parkName: string) {
        if (parkName) {
            setParque(parkData[brand][parkName]);
            setParkName(parkName);
            setNewParkName(parkName);
            setFila(parkData[brand][parkName].rows.slice(-1)[0]);
            setLugares(parkData[brand][parkName].spots.slice(-1)[0]);
            setLocationName(parkData[brand][parkName].locationName);
            setLocation(parkData[brand][parkName].locationLink);
            setUnavailableSpots(parkData[brand][parkName].unavailableSpots);
        } else setParque(null);
    }

    async function remove() {
        if (!window.confirm(`Tem a certeza que quer apagar o parque '${parkName}'?`)) {
            return;
        }

        try {
            const brandDocRef = doc(db, 'faro', brand);
            const brandDocSnap = await getDoc(brandDocRef);
            if (!brandDocSnap.exists()) {
                throw new Error(`Document ${brand} does not exist!`);
            }
            const parksArray = brandDocSnap.data().parks;
            // Filter out the park to remove
            const updatedParksArray = parksArray.filter((park: any) => park.name !== parkName);
            await updateDoc(brandDocRef, { parks: updatedParksArray });
            // Update local state to reflect this change
            setParkData((prevParkData: any) => {
                const updatedParkData = { ...prevParkData };
                if (updatedParkData && updatedParkData[brand]) {
                    updatedParkData[brand] = updatedParksArray;
                }
                return updatedParkData;
            });
            openDialog(`Parque '${parkName}' removido com sucesso.`, () => {
                setParque(null);
                setParkName('');
                setNewParkName('');
                setLocationName('');
                setLocation('');
                setFila(0);
                setLugares(0);
                setUnavailableSpots(null);
            });
        } catch (error) {
            console.error("Error removing park: ", error);
            openDialog(`Erro ao remover o parque '${parkName}'.`, () => {});
        }
    }

    async function edit() {
        if (!newParkName || !locationName || !location) {
            openDialog('Preencha todos os campos.', () => null)
            return;
        }
        if (fila <= 0 || lugares <= 0) {
            openDialog('Número de filas ou lugares inválido.', () => null)
            return;
        }
        for (let i = 0; i < unavailableSpots.length; i++) {
            const ele = unavailableSpots[i];
            if (ele.row <= 0 || ele.spot <= 0 || ele.row > fila || ele.spot > lugares) {
                openDialog('Fila/Lugar indiponível deve ser superior a 0 e inferior ao número de filas/lugares', () => null);
                return;
            }
        }
        if (!window.confirm(`Tem a certeza que quer editar o parque '${parkName}'?`)) {
            return;
        }
        try {
            const brandDocRef = doc(db, 'faro', brand);
            const brandDocSnap = await getDoc(brandDocRef);
            if (!brandDocSnap.exists()) {
                throw new Error(`Document ${brand} does not exist!`);
            }
            let parksArray = brandDocSnap.data().parks;

            // Find the index of the park to edit
            const parkIndex = parksArray.findIndex((park: any) => park.name === parkName);
            if (parkIndex === -1) {
                throw new Error(`Park ${parkName} does not exist!`);
            }

            // Update the park's properties
            parksArray[parkIndex] = {
                ...parksArray[parkIndex],
                name: newParkName,
                locationLink: location,
                locationName: locationName,
                unavailableSpots: unavailableSpots,
                rows: Array.from({ length: fila }, (_, i) => i + 1),
                spots: Array.from({ length: lugares }, (_, i) => i + 1)
            };

            // Save the updated parks array back to Firestore
            await updateDoc(brandDocRef, { parks: parksArray });

            // Update local state to reflect this change
            setParkData((prevParkData: any) => {
                const updatedParkData = { ...prevParkData };
                if (updatedParkData && updatedParkData[brand]) {
                    delete updatedParkData[brand][parkName];
                    updatedParkData[brand][newParkName] = parksArray[parkIndex];
                }
                return updatedParkData;
            });

            openDialog(`Parque '${parkName}' editado com sucesso.`, () => {
                setParque(null);
                setParkName('');
                setNewParkName('');
                setLocationName('');
                setLocation('');
                setUnavailableSpots(null);
                setFila(0);
                setLugares(0);
            });

        } catch (error) {
            console.error("Error editing park: ", error);
            openDialog(`Erro ao editar o parque '${parkName}'.`, () => {});
        }
    }


    return (
        <div className='w-full pw-[8vw] flex justify-center'>
            <button onClick={() => navigate(-1)} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='w-full cinco:w-[25rem] flex flex-col items-center gap-10 py-20 cinco:py-8'>
                <h1 className='text-[var(--primary)] font-mybold text-[1.2rem] cinco:text-[1.5rem] uppercase'>PARQUES</h1>
                <div className='cinco:px-[3rem] w-full flex flex-col gap-4'>
                    <button onClick={() => navigate('/settings/parques/add')} className='w-full text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-white uppercase'>+ Novo Parque interno</button>
                    <button onClick={() => navigate('/settings/parques/extern/add')} className='w-full text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-white uppercase'>+ Novo Parque externo</button>
                    <div className='flex gap-4'>
                        <p><span className='font-semibold'>Cidade:</span> {city}</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <label htmlFor="brand-input" className='font-semibold'>Marca:</label>
                        <select
                            name="parque"
                            value={brand}
                            className="uppercase select"
                            onChange={(e) => setBrand(e.target.value)}
                        >
                            <option value="">Selecionar</option>
                            {
                                parkData && city && parkData && Object.keys(parkData).map((park: string, index: number) => {
                                    return (
                                        <option key={index} value={park} className='uppercase'>{park}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    {
                        brand &&
                        <div className='flex flex-col gap-2'>
                            <label htmlFor="brand-input" className='font-semibold'>Parque:</label>
                            <select
                                name="parque"
                                className="uppercase select"
                                onChange={(e) => changeParque(e.target.value)}
                            >
                                <option value="">Selecionar</option>
                                {
                                    parkData && city && brand && parkData[brand] && Object.keys(parkData[brand]).map((park: string, index: number) => {
                                        return (
                                            <option key={index} value={park} className='uppercase'>{park}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    }
                    {
                        brand && parque &&
                        <>
                            <div className='flex flex-col w-full gap-2'>

                                <label className='font-semibold'>Nome do Parque:</label>
                                <input type="text" value={newParkName} onChange={(e) => setNewParkName(e.target.value)} className='relative w-full px-2 py-1 border-2 border-black rounded-full' placeholder='Nome do parque' />

                                <div className='flex w-full gap-4'>
                                    <div className='flex flex-col w-full'>
                                        <label className='font-semibold'>Nº de Filas:</label>
                                        <input type="number" value={fila ? fila : ''} onChange={(e) => setFila(parseInt(e.target.value))} className='relative w-full px-2 py-1 border-2 border-black rounded-full' placeholder='Nº filas' />
                                    </div>

                                    <div className='flex flex-col w-full'>
                                        <label className='font-semibold'>Nº de Lugares:</label>
                                        <input type="number" value={lugares ? lugares : ''} onChange={(e) => setLugares(parseInt(e.target.value))} className='relative w-full px-2 py-1 border-2 border-black rounded-full' placeholder='Nº lugares' />
                                    </div>
                                </div>

                                <label className='font-semibold'>Cidade | Zona:</label>
                                <input type="text" value={locationName} onChange={(e) => setLocationName(e.target.value)} className='relative w-full px-2 py-1 border-2 border-black rounded-full' placeholder='Cidade | Zona' />

                                <label className='font-semibold'>Link de Localização Maps:</label>
                                <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} className='relative w-full px-2 py-1 border-2 border-black rounded-full' placeholder='Link de localização maps' />
                                <div className='flex items-center justify-between'>
                                    <label className='font-semibold'>Lugares indisponíveis:</label>
                                    <button onClick={() => setUnavailableSpots((prev: any) => [...prev, { row: 0, spot: 0 }])} className='px-6 text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-1 text-white uppercase'>+ add</button>
                                </div>
                                {
                                    unavailableSpots && unavailableSpots.length !== 0 &&
                                    <div className="flex w-full gap-4">
                                        <label className='w-full'>Fila:</label>
                                        <label className='w-full'>Lugar:</label>
                                    </div>
                                }
                                {
                                    unavailableSpots && unavailableSpots.length !== 0 ? unavailableSpots.map((ele: any, index: number) => {
                                        return (
                                            <div key={index} className='flex items-center w-full gap-4'>
                                                <input type="number" className='relative w-full px-2 py-1 border-2 border-black rounded-full' value={ele.row} onChange={(e) => setUnavailableSpots((prev: any) => prev.map((item: any, idx: number) => idx === index ? { ...item, row: parseInt(e.target.value) } : item))} placeholder='Fila' />
                                                <input type="number" className='relative w-full px-2 py-1 border-2 border-black rounded-full' value={ele.spot} onChange={(e) => setUnavailableSpots((prev: any) => prev.map((item: any, idx: number) => idx === index ? { ...item, spot: parseInt(e.target.value) } : item))} placeholder='Lugar' />
                                                <div onClick={() => setUnavailableSpots((prev: any) => prev.filter((ele: any, idx: number) => idx !== index))} className='w-8 shrink-0 h-8 bg-[var(--red)] rounded-full flex justify-center items-center cursor-pointer'><img src={Bin} alt="bin" className='w-4' /></div>

                                            </div>
                                        )
                                    }) :
                                        <p className='w-full text-center'>Nenhum lugar indisponível</p>
                                }
                            </div>

                            <div className='flex w-full gap-4'>
                                <button onClick={edit} className='w-full text-[1rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-black'>SALVAR</button>
                                <button onClick={remove} className='w-full text-[1rem] bg-[var(--red)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-white flex gap-2 items-center justify-center'>
                                    APAGAR
                                    <img src={Bin} alt="icon" className='w-5' />
                                </button>
                            </div>
                        </>
                    }
                </div>
                <Dialog
                    message={dialogMessage}
                    opened={dialogOpen}
                    onDialog={closeDialog}
                    onClose={dialogOnClose}
                />
            </div>
        </div>
    );
}

